import smartlookClient from 'smartlook-client';

import { IS_LOCAL_DEV, IS_TEST, SMARTLOOK_KEY, SERVER_MODE } from '../config';
import { Logger } from '../utils';

import { TrackerEvent, IBuilding, IUser, ServerMode } from '@poormanvr/common';

export function isTrackingDisabled() {
  if (!SMARTLOOK_KEY) return true;
  if (SERVER_MODE !== ServerMode.DEFAULT) return true;
  return IS_TEST || IS_LOCAL_DEV;
}

export function initialize() {
  if (!SMARTLOOK_KEY) {
    Logger.warn('EventTracker', 'not initialized - key missing');
    return;
  }
  if (isTrackingDisabled()) return;
  try {
    smartlookClient.init(SMARTLOOK_KEY);
    Logger.debug('EventTracker', 'initialized');
  } catch (e) {
    Logger.error('EventTracker:initialize', e);
  }
}

export function test() {
  trackEvent(TrackerEvent.Type.TEST_TRACKER, { value: 'foo' });
}

export function identify(user: IUser | null, building: IBuilding | null) {
  if (isTrackingDisabled()) return;
  if (!user?.id) {
    Logger.warn('EventTracker', 'identification failed - user missing');
    return;
  }
  try {
    const role = IS_LOCAL_DEV ? 'developer' : user.admin ? 'admin' : 'attendee';
    const anonymousIdentifier = `${role}-${user.id}`;
    smartlookClient.identify(user.id, {
      name: anonymousIdentifier,
      buildingId: building?.id || '',
    });
    Logger.debug(
      'EventTracker:identify',
      `${anonymousIdentifier} ${building?.id}`,
    );
  } catch (e) {
    Logger.error('EventTracker:identify', e);
  }
}

export function anonymize() {
  if (isTrackingDisabled()) return;
  try {
    smartlookClient.anonymize();
  } catch (e) {
    Logger.error('EventTracker:anonymize', e);
  }
}

export function disable() {
  if (isTrackingDisabled()) return;
  try {
    if (isTrackingDisabled()) return;
    Logger.debug('EventTracker', 'disabled');
  } catch (e) {
    Logger.error('EventTracker:disable', e);
  }
}

const DEFALT_OPTIONS: Record<string, string> = {};

export function trackEvent<
  T extends TrackerEvent.Type,
  O extends TrackerEvent.Options[T]
>(type: T, options?: O) {
  if (isTrackingDisabled()) return;
  try {
    if (options) {
      smartlookClient.track(
        type,
        options as Record<string, string | number | boolean>,
      );
    } else {
      smartlookClient.track(type, DEFALT_OPTIONS);
    }
    Logger.debug('EventTracker', `track ${type} ${options}`);
  } catch (e) {
    Logger.error(`EventTracker:trackEvent:${type}`, e);
  }
}
