import { captureException } from '@sentry/browser';
import { IS_LOCAL_DEV, IS_TEST } from '../config';

export function info(scope: string, value?: Error | string | unknown) {
  console.info(`[${scope}] Info:`, value);
}

export function debug(scope: string, value?: Error | string | unknown) {
  console.debug(`[${scope}] Debug:`, value);
}

export function warn(scope: string, value?: Error | string | unknown) {
  console.warn(`[${scope}] Warning:`, value);
}

export function error(scope: string, value?: Error | string | unknown) {
  if (!IS_LOCAL_DEV && !IS_TEST) {
    if (value instanceof Error) {
      captureException(error);
    } else if (typeof value === 'string') {
      const error = new Error(`[${scope}] Error: value`);
      captureException(error);
    } else {
      const error = new Error(`An error occurred at [${scope}]`);
      captureException(error);
    }
  }
  console.error(`[${scope}] Error:`, value);
}
