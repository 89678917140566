import styles from './index.module.scss';

function parsePercentage(value: string) {
  return Number.parseFloat(value) / 100;
}

function parsePixel(value: string) {
  return Number.parseFloat(value);
}

/**
 * Parse the <time> CSS data type as milliseconds
 *
 * @param value `1.5s`, `300ms`, etc.
 */
function parseTimeAsMillis(value: string) {
  const number = Number.parseFloat(value);
  if (/ms$/i.test(value)) return number;
  return number * 1e3;
}

export const COLOR_ALERT = styles.colorAlert;
export const COLOR_ENGAGEMENT = styles.colorEngagement;
export const COLOR_USER = styles.colorUser;

export const MIX_WEIGHT_DARK = parsePercentage(styles.mixWeightDark);
export const MIX_WEIGHT_LIGHT = parsePercentage(styles.mixWeightLight);
export const MIX_WEIGHT_LIGHTER = parsePercentage(styles.mixWeightLighter);

export const ANIM_DURATION = parseTimeAsMillis(styles.animDuration);

export const GRID_SIZE_LARGE = parsePixel(styles.gridSizeLarge);

export const COLOR_SHADE_20 = styles.colorShade20;
export const COLOR_SHADE_50 = styles.colorShade50;
export const COLOR_SHADE_80 = styles.colorShade80;

export const COLOR_WHITE = styles.colorWhite;
export const COLOR_BLACK = styles.colorBlack;
export const COLOR_GRAY_20 = styles.colorGray20;
export const COLOR_GRAY_50 = styles.colorGray50;
export const COLOR_GRAY_70 = styles.colorGray70;

export const COLOR_AVATAR_DEFAULT = styles.colorAvatarDefault;

export const FONT_SIZE = styles.fontSize;
export const FONT_FAMILY = styles.fontFamily;

export const COLOR_LOGO_BLUE = styles.colorLogoBlue;
export const COLOR_LOGO_GREEN = styles.colorLogoGreen;

export const MY_HUDDLE_COLOR = styles.myHuddleColor;
export const UNLOCKED_HUDDLE_COLOR = styles.unlockedHuddleColor;
export const LOCKED_HUDDLE_COLOR = styles.lockedHuddleColor;
