export const MILLISECOND = 1;
export const SECOND = 1000 * MILLISECOND;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const MONTH = 30 * DAY;
export const YEAR = 365 * DAY;

export const daysCountdown = (seconds: number): string => {
  const days = Math.floor(seconds / (24 * 3600));

  return String(days).padStart(2, '0');
};

export const hoursCountdown = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600) % 24;
  return String(hours).padStart(2, '0');
};

export const minutesCountdown = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60) % 60;
  return String(minutes).padStart(2, '0');
};

export const secondsCountdown = (seconds: number): string => {
  return String(seconds % 60).padStart(2, '0');
};
