import React, {
  createContext,
  Dispatch,
  ReactNode,
  useMemo,
  useState,
} from 'react';

interface Value {
  cameraId: string | null;
  micId: string | null;
  speakerId: string | null;
  setCameraId: Dispatch<string | null>;
  setMicId: Dispatch<string | null>;
  setSpeakerId: Dispatch<string | null>;
  isCameraEnabled: boolean;
}

export const DeviceContext = createContext<Value>({
  cameraId: null,
  micId: null,
  speakerId: null,
  setCameraId: () => 0,
  setMicId: () => 0,
  setSpeakerId: () => 0,
  isCameraEnabled: false,
});

interface Props {
  children: ReactNode;
}

export function DeviceProvider({ children }: Props) {
  const [cameraId, setCameraId] = useState<string | null>(null);
  const [micId, setMicId] = useState<string | null>(null);
  const [speakerId, setSpeakerId] = useState<string | null>(null);

  const value: Value = useMemo<Value>(
    () => ({
      cameraId,
      micId,
      speakerId,
      setCameraId,
      setMicId,
      setSpeakerId,
      isCameraEnabled: !!cameraId,
    }),
    [cameraId, micId, speakerId, setCameraId, setMicId, setSpeakerId],
  );

  return (
    <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>
  );
}
