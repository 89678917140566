import React from 'react';
import { c } from '../../utils';
import { Clickable } from '../Clickable';
import classes from './GridButton.module.scss';

interface Props extends React.ComponentProps<typeof Clickable> {
  size?: 'large' | 'medium' | 'small';
  active?: boolean;
  warned?: boolean;
  alerted?: boolean;
  highlighted?: boolean;
  showLabel?: boolean;
}

export function GridButton({
  className,
  size,
  active,
  warned,
  alerted,
  highlighted,
  disabled,
  label,
  showLabel,
  children,
  ...restProps
}: Props) {
  return (
    <Clickable
      className={c(
        classes.GridButton,
        size && classes[size],
        active && classes.active,
        warned && classes.warned,
        alerted && classes.alerted,
        highlighted && classes.highlighted,
        disabled && classes.disabled,
        className,
      )}
      disabled={disabled}
      label={label}
      {...restProps}
    >
      {children}
      {showLabel && <div className={classes.label}>{label}</div>}
    </Clickable>
  );
}
