import { v4 as uuid } from 'uuid';
import { DateTime } from 'luxon';
import {
  COLOR_BLACK,
  COLOR_GRAY_70,
  COLOR_LOGO_BLUE,
  COLOR_LOGO_GREEN,
  COLOR_WHITE,
  LOCKED_HUDDLE_COLOR,
  MY_HUDDLE_COLOR,
  UNLOCKED_HUDDLE_COLOR,
} from '../styles';
import { Images } from '../images';
import logoSvg from '../assets/logo_narrow.svg';
import { ellipsis, Logger } from '.';
import {
  AugmentedBetterDmvEvent,
  Dictionary,
  IBuilding,
  IFloor,
  IHuddle,
  IPhoto,
  IUser,
  MAP_HEIGHT,
  MAP_WIDTH,
  PhotoStatus,
} from '@poormanvr/common';

// Source: https://blog.hootsuite.com/social-media-image-sizes-guide/
const DIMENSIONS: { [key: string]: { height: number; width: number } } = {
  LinkedIn: {
    height: 627,
    width: 1200,
  },
  Facebook: {
    height: 630,
    width: 1200,
  },
  Twitter: {
    height: 512,
    width: 1024,
  },
};

const SPONSORS_HEIGHT = 136;
const SPONSORS_PADDING = 16;
const PADDING_SIZE = 4;
const BORDER_SIZE = 4;

const maxContentHeight = Object.keys(DIMENSIONS).reduce(
  (value: number, key: string) => {
    if (!value) return DIMENSIONS[key].height;
    if (DIMENSIONS[key].height < value) return DIMENSIONS[key].height;
    return value;
  },
  0,
);

const maxContentWidth = Object.keys(DIMENSIONS).reduce(
  (value: number, key: string) => {
    if (!value) return DIMENSIONS[key].width;
    if (DIMENSIONS[key].width < value) return DIMENSIONS[key].width;
    return value;
  },
  0,
);

function setFillStyleToLogoGradient(
  ctx: CanvasRenderingContext2D,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
) {
  const gradient = ctx.createLinearGradient(x1, y1, x2, y2);

  gradient.addColorStop(0, COLOR_LOGO_BLUE);
  gradient.addColorStop(1, COLOR_LOGO_GREEN);

  ctx.fillStyle = gradient;
}

async function loadFonts() {
  const Montserrat500 = new FontFace(
    'Montserrat',
    'url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gnD_g.woff2)',
    {
      style: 'normal',
      weight: '500',
    },
  );
  const Montserrat700 = new FontFace(
    'Montserrat',
    'url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE3gnD_g.woff2)',
    {
      style: 'normal',
      weight: '700',
    },
  );

  await Montserrat500.load();
  await Montserrat700.load();

  document.fonts.add(Montserrat500);
  document.fonts.add(Montserrat700);
}

function loadImages(logo: string, map: string | null | undefined) {
  try {
    Logger.debug('photo:loadImages', 'loading images init');

    if (map) {
      const images: { [key: string]: string } = {
        logo,
        map,
        availableTickets: Images.AVAILABLE_TICKETS.src,
        reservedTickets: Images.RESERVED_TICKETS.src,
        charactersLeft: Images.CHARACTERS_LEFT.src,
        charactersRight: Images.CHARACTERS_RIGHT.src,
      };

      return Promise.all(
        Object.keys(images).map(key => {
          const promise = new Promise<{ name: string; elm: HTMLImageElement }>(
            (resolve, reject) => {
              const img = new Image();

              img.onload = () => {
                resolve({ name: key, elm: img });
              };

              img.onerror = (e: string | Event) => {
                Logger.error('photo:loadImages', e);

                reject('error while loading images');
              };

              /*
              HMTL Canvas requires all externally loaded images for be downloaded
              via a valid CORS policy for it to permit that image to be exportable
              as a PNG. Adding this query string to the image url prevents the browse
              from trying to use a cached version of the image which may have been
              downloaded under less strict conditions
            */
              img.crossOrigin = key === 'map' ? 'crossorigin' : 'anonymous';
              img.src = `${images[key]}${key === 'map' ? `?${uuid()}` : ''}`;
            },
          );

          return promise;
        }),
      );
    } else {
      throw new Error('no map');
    }
  } catch (e) {
    Logger.debug('photo:loadImages', e);
  }
}

export function getEventInfo(eventInfo: AugmentedBetterDmvEvent | null) {
  let eventHost = 'Event Host Logo/Name';
  let eventName = 'Event Name';
  let startTime = DateTime.now();
  let endTime = DateTime.now();
  let date = startTime.toLocaleString(DateTime.DATE_FULL);

  if (eventInfo) {
    eventHost = eventInfo.hostCompany ?? 'Gatherly';
    eventName = eventInfo.name;
    startTime = DateTime.fromMillis(eventInfo.startTime);
    endTime = DateTime.fromMillis(eventInfo.stopTime);
  }

  if (startTime.year !== endTime.year) {
    date =
      startTime.toLocaleString({ month: 'short', year: 'numeric' }) +
      ' - ' +
      endTime.toLocaleString({ month: 'short', year: 'numeric' });
  } else if (startTime.month !== endTime.month) {
    date =
      startTime.toLocaleString({ month: 'short', day: '2-digit' }) +
      ' - ' +
      endTime.toLocaleString({ month: 'short', day: '2-digit' }) +
      ', ' +
      endTime.year;
  } else if (startTime.day !== endTime.day) {
    date =
      startTime.toLocaleString({ month: 'short' }) +
      ' ' +
      startTime.toLocaleString({ day: '2-digit' }) +
      ' - ' +
      endTime.toLocaleString({ day: '2-digit' }) +
      ', ' +
      endTime.year;
  }

  return {
    eventHost,
    eventName,
    date,
  };
}

interface IGetTileDimensions {
  count: number;
  availableWidth: number;
  availableHeight: number;
}

export function getTileDimensions({
  count,
  availableWidth,
  availableHeight,
}: IGetTileDimensions) {
  let columns = 0;
  let rows = 0;
  let scaleX = 1;
  let scaleY = 1;

  if (count <= 4) {
    columns = 2;
    rows = 2;
  } else if (count <= 9) {
    columns = 3;
    rows = 3;
  } else {
    columns = 4;
    rows = 4;
  }

  const bestTileWidth = Math.floor(availableWidth / 2);
  const bestTileHeight = Math.floor(availableHeight / 2);
  const tileWidth = Math.floor(availableWidth / columns);
  const tileHeight = Math.floor(availableHeight / rows);

  scaleX = tileWidth / bestTileWidth;
  scaleY = tileHeight / bestTileHeight;

  return {
    columns,
    rows,
    tileWidth,
    tileHeight,
    scaleX,
    scaleY,
  };
}

enum TileType {
  TEXT,
  IMAGE,
}

/**
 * *Note: `ctx.font` must have already been declared before calling this function.*
 */
function getTextLines(
  ctx: CanvasRenderingContext2D,
  text: string,
  width: number,
) {
  const words = text.split(' ');
  const lines: string[] = [];

  let line = '';

  for (const word of words) {
    const tempLine = line + word + ' ';
    const tempLineWidth = ctx.measureText(tempLine).width;

    if (tempLineWidth > width) {
      lines.push(line.slice(0, -1));

      line = word + ' ';
    } else {
      line = tempLine;
    }
  }

  lines.push(line.slice(0, -1));

  return lines;
}

function drawRoundedRectangle(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: number,
) {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
}

interface IText {
  ctx: CanvasRenderingContext2D;
  text: string;
  x: number;
  y: number;
  font: string;
  fontSize: number;
  center: boolean;
  width?: number;
  fontWeight?: string;
  overflow?: boolean;
}

function drawText({
  ctx,
  text,
  x,
  y,
  font,
  fontSize,
  center,
  width,
  fontWeight = 'normal',
  overflow = true,
}: IText) {
  let renderedX = x;

  ctx.save();
  ctx.font = `${fontWeight} ${fontSize}px ${font}`;
  ctx.textBaseline = 'top';

  if (center) {
    ctx.textAlign = 'center';

    if (width) renderedX = x + width / 2;
    else {
      renderedX = x + ctx.measureText('text').width / 2;
    }
  }

  let renderedText = text;

  if (!overflow && width) {
    const ellipsis = '…';
    const ellipsisWidth = ctx.measureText(ellipsis).width;

    let textWidth = ctx.measureText(text).width;

    if (textWidth > width) {
      let newText = text;
      let len = text.length;

      while (textWidth > width - ellipsisWidth && len-- > 0) {
        newText = newText.substring(0, len);

        textWidth = ctx.measureText(newText).width;
      }

      renderedText = newText + ellipsis;
    }
  }

  ctx.fillText(renderedText, renderedX, y);
  ctx.restore();
}

function getCroppedDimensions(
  srcWidth: number,
  srcHeight: number,
  destWidth: number,
  destHeight: number,
) {
  const scale = Math.max(destWidth / srcWidth, destHeight / srcHeight);
  const newWidth = srcWidth * scale;
  const newHeight = srcHeight * scale;

  let croppedWidth = 0;
  let croppedHeight = 0;

  if (newWidth > destWidth) {
    croppedWidth = srcWidth * (newWidth / destWidth - 1);
  }

  if (newHeight > destHeight) {
    croppedHeight = srcHeight * (newHeight / destHeight - 1);
  }

  return {
    croppedWidth,
    croppedHeight,
  };
}

function drawVideoTile(
  ctx: CanvasRenderingContext2D,
  video: HTMLVideoElement,
  name: string,
  scalingFactor: number,
  x: number,
  y: number,
  width: number,
  height: number,
) {
  ctx.save();
  ctx.translate(x + width, y);
  ctx.scale(-1, 1);

  const { croppedWidth, croppedHeight } = getCroppedDimensions(
    video.videoWidth,
    video.videoHeight,
    width,
    height,
  );

  ctx.drawImage(
    video,
    croppedWidth / 2,
    croppedHeight / 2,
    video.videoWidth - croppedWidth,
    video.videoHeight - croppedHeight,
    0,
    0,
    width,
    height,
  );

  ctx.restore();

  ctx.strokeRect(
    x + BORDER_SIZE / 2,
    y + BORDER_SIZE / 2,
    width - BORDER_SIZE,
    height - BORDER_SIZE,
  );

  const fontWeight = '400';
  const fontSize = 16 * scalingFactor;
  const fontName = 'Lato';

  ctx.font = `${fontWeight} ${fontSize}px ${fontName}`;

  const nameHeight = fontSize + PADDING_SIZE * 2;
  const nameWidth = ctx.measureText(name).width + PADDING_SIZE * 2;
  const nameX = x + width - nameWidth - PADDING_SIZE * 2;
  const nameY = y + height - nameHeight - PADDING_SIZE * 2;

  ctx.fillStyle = COLOR_WHITE;

  drawRoundedRectangle(ctx, nameX, nameY, nameWidth, nameHeight, 4);

  ctx.fillStyle = COLOR_GRAY_70;

  drawText({
    ctx,
    text: name,
    x: nameX,
    y: nameY + PADDING_SIZE,
    font: fontName,
    fontSize,
    fontWeight,
    center: true,
    width: nameWidth,
  });
}

function drawTiles(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  userIds: string[],
  names: { [key: string]: string },
  images: { [key: string]: HTMLImageElement },
  eventName: string,
  date: string,
) {
  const videoNodes = document.querySelectorAll('video');
  const videos = Array.from(videoNodes).filter(
    node => node.videoWidth && userIds.includes(node.dataset.userId ?? ''),
  );
  const {
    columns,
    rows,
    tileWidth,
    tileHeight,
    scaleX,
    scaleY,
  } = getTileDimensions({
    count: videos.length,
    availableWidth: width,
    availableHeight: height,
  });

  ctx.lineWidth = BORDER_SIZE;
  ctx.strokeStyle = COLOR_WHITE;
  ctx.fillStyle = COLOR_WHITE;

  let currentX = x;
  let currentY = y + height - tileHeight;
  let currentColumn = 0;
  let previousTile = Math.random() > 0.5 ? TileType.TEXT : TileType.IMAGE;

  const availableImages = [
    'availableTickets',
    'reservedTickets',
    'charactersLeft',
    'charactersRight',
  ];
  const renderedWidth = tileWidth - PADDING_SIZE * 2;
  const renderedHeight = tileHeight - PADDING_SIZE * 2;
  const mainFontWeight = '700';
  const secondaryFontWeight = '500';
  const mainFontSize = 18 * scaleX;
  const secondaryFontSize = 14;
  const fontName = 'Montserrat';
  const lineHeight = 22 * scaleY;

  ctx.font = `${mainFontWeight} ${mainFontSize}px ${fontName}`;

  const lines = getTextLines(ctx, eventName.toLocaleUpperCase(), renderedWidth);
  const textPosition =
    (renderedHeight - lineHeight * (lines.length + 1) - 8) / 2;

  for (let i = 0; i < columns * rows; i++) {
    const renderedX = currentX + PADDING_SIZE;
    const renderedY = currentY + PADDING_SIZE;

    if (i < videos.length) {
      drawVideoTile(
        ctx,
        videos[i],
        names[videos[i].dataset.userId ?? ''],
        scaleY,
        renderedX,
        renderedY,
        renderedWidth,
        renderedHeight,
      );
    } else {
      if (previousTile === TileType.IMAGE) {
        let textY = renderedY + textPosition;

        ctx.fillStyle = COLOR_WHITE;

        for (const line of lines) {
          drawText({
            ctx,
            text: line,
            x: renderedX,
            y: textY,
            font: fontName,
            fontSize: mainFontSize,
            fontWeight: mainFontWeight,
            center: true,
            width: renderedWidth,
          });

          textY += lineHeight;
        }

        drawText({
          ctx,
          text: date,
          x: renderedX,
          y: textY + 10 * scaleY,
          font: fontName,
          fontSize: secondaryFontSize,
          fontWeight: secondaryFontWeight,
          center: true,
          width: renderedWidth,
        });
      } else {
        const img = availableImages.splice(
          Math.floor(Math.random() * availableImages.length),
          1,
        );

        if (img.length) {
          ctx.drawImage(
            images[img[0]],
            renderedX,
            renderedY,
            tileWidth,
            tileHeight,
          );
        }
      }
      previousTile =
        previousTile === TileType.IMAGE ? TileType.TEXT : TileType.IMAGE;
    }

    if (currentColumn >= columns - 1) {
      currentX = x + -tileWidth;
      currentY -= tileHeight;
      currentColumn = -1;
    }

    currentX += tileWidth;

    currentColumn++;
  }
}

interface IMap {
  ctx: CanvasRenderingContext2D;
  x: number;
  y: number;
  width: number;
  height: number;
  floorMap: HTMLImageElement;
  floorName: string;
  huddleId: string | undefined;
  me: IUser | null;
  huddles: IHuddle[];
  huddlesMembers: Dictionary<IUser[]>;
}

function drawMap({
  ctx,
  x,
  y,
  width,
  height,
  floorMap,
  floorName,
  huddleId,
  me,
  huddles,
  huddlesMembers,
}: IMap) {
  const { croppedWidth, croppedHeight } = getCroppedDimensions(
    floorMap.width,
    floorMap.height,
    width,
    height,
  );

  let mapX = croppedWidth / 2;
  let mapY = croppedHeight / 2;

  if (me) {
    mapX = me.point.x > MAP_WIDTH / 2 ? croppedWidth : 0;
    mapY = me.point.y > MAP_HEIGHT / 2 ? croppedHeight : 0;
  }

  ctx.save();

  ctx.drawImage(
    floorMap,
    mapX,
    mapY,
    floorMap.width - croppedWidth,
    floorMap.height - croppedHeight,
    x,
    y,
    width,
    height,
  );

  if (huddleId) {
    const radius = 24;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.font = `700 36px Lato`;

    for (const huddle of huddles) {
      let color = UNLOCKED_HUDDLE_COLOR;

      if (huddle.id === huddleId) {
        color = MY_HUDDLE_COLOR;
      }

      if (huddle.locked) {
        color = LOCKED_HUDDLE_COLOR;
      }

      ctx.fillStyle = color;

      const huddleX = x + huddle.point.x * 1.03;
      const huddleY = y + huddle.point.y * 1.34;

      ctx.beginPath();
      ctx.arc(huddleX, huddleY, radius, 0, 2 * Math.PI);
      ctx.fill();

      ctx.fillStyle = COLOR_WHITE;
      ctx.fillText(`${huddlesMembers[huddle.id].length}`, huddleX, huddleY);
    }
  } else if (me) {
    const radius = 20;

    setFillStyleToLogoGradient(
      ctx,
      x + me.point.x - 8,
      y + me.point.y + 8,
      x + me.point.x + 8,
      y + me.point.y - 8,
    );

    ctx.strokeStyle = COLOR_WHITE;
    ctx.lineWidth = 1;

    const diamondX = x + me.point.x * 1.03;
    const diamondY = y + me.point.y * 1.34;
    const centerX = diamondX + radius / 2;
    const centerY = diamondY + radius / 2;

    ctx.save();

    ctx.translate(centerX, centerY);
    ctx.rotate(Math.PI / 4);
    ctx.translate(-centerX, -centerY);

    drawRoundedRectangle(ctx, diamondX, diamondY, radius, radius, 4);

    ctx.stroke();

    ctx.restore();

    ctx.fillStyle = COLOR_BLACK;
    ctx.textAlign = 'center';

    drawText({
      ctx,
      text: ellipsis(me.name, 10),
      x: centerX,
      y: diamondY + radius + PADDING_SIZE,
      font: 'Lato',
      fontSize: 20,
      center: false,
    });
  }

  ctx.fillStyle = COLOR_WHITE;
  ctx.strokeStyle = COLOR_WHITE;

  const fontWeight = '400';
  const fontSize = 16;
  const fontName = 'Lato';

  ctx.font = `${fontWeight} ${fontSize}px ${fontName}`;

  const nameWidth = ctx.measureText(floorName).width + PADDING_SIZE * 2;
  const nameHeight = fontSize + PADDING_SIZE * 2;
  const nameX = x + width - nameWidth - BORDER_SIZE / 2;
  const nameY = y + height - nameHeight - BORDER_SIZE / 2;

  drawRoundedRectangle(ctx, nameX, nameY, nameWidth, nameHeight, 4);

  ctx.fillStyle = COLOR_GRAY_70;

  drawText({
    ctx,
    text: floorName,
    x: nameX,
    y: nameY + PADDING_SIZE,
    font: fontName,
    fontSize,
    fontWeight,
    center: true,
    width: nameWidth,
  });

  ctx.lineWidth = BORDER_SIZE;

  ctx.strokeRect(
    x + BORDER_SIZE / 2,
    y + BORDER_SIZE / 2,
    width - BORDER_SIZE,
    height - BORDER_SIZE,
  );

  ctx.restore();
}

function drawEventLogo(
  ctx: CanvasRenderingContext2D,
  logo: string,
  x: number,
  y: number,
  width: number,
  height: number,
) {
  return new Promise(resolve => {
    const logoImage = new Image();

    logoImage.onload = () => {
      const imageX = x + SPONSORS_PADDING;

      let scaleFactor = (height - SPONSORS_PADDING * 2) / logoImage.height;
      let imageWidth = logoImage.width * scaleFactor;
      let imageHeight = logoImage.height * scaleFactor;

      if (imageWidth > width) {
        scaleFactor = imageWidth / width;
        imageWidth = logoImage.width * scaleFactor;
        imageHeight = logoImage.height * scaleFactor;
      }

      const imageY = y + (height - imageHeight) / 2;

      ctx.drawImage(logoImage, imageX, imageY, imageWidth, imageHeight);

      resolve(imageWidth);
    };

    logoImage.crossOrigin = 'anonymous';
    logoImage.src = logo;
  });
}

async function drawSponsors(
  ctx: CanvasRenderingContext2D,
  sponsorsX: number,
  sponsorsY: number,
  sponsorsWidth: number,
  sponsorsHeight: number,
  building: IBuilding | null,
  eventHost: string,
  images: { [key: string]: HTMLImageElement },
) {
  let eventLogoWidth = sponsorsWidth / 3 - SPONSORS_PADDING;

  if (building?.logoUrl) {
    const renderedEventLogoWidth = await drawEventLogo(
      ctx,
      building.logoUrl,
      sponsorsX,
      sponsorsY,
      eventLogoWidth,
      sponsorsHeight,
    );

    if (typeof renderedEventLogoWidth === 'number') {
      eventLogoWidth = renderedEventLogoWidth;
    }
  } else {
    const fontName = 'Lato';
    const fontSize = 30;
    const fontWeight = '700';
    const lineHeight = 36;

    ctx.font = `${fontWeight} ${fontSize}px ${fontName}`;
    ctx.fillStyle = '#004461';
    ctx.textBaseline = 'top';

    const lines = getTextLines(ctx, eventHost, eventLogoWidth);

    lines.splice(3);

    const eventLogoX = sponsorsX + SPONSORS_PADDING;

    let eventLogoY =
      sponsorsY + (sponsorsHeight - lines.length * lineHeight) / 2;
    let eventLogoMaxWidth = 0;

    for (const line of lines) {
      ctx.fillText(line, eventLogoX, eventLogoY, eventLogoWidth);

      const lineWidth = ctx.measureText(line).width;

      if (eventLogoMaxWidth < lineWidth) {
        eventLogoMaxWidth = lineWidth;
      }

      eventLogoY += lineHeight;
    }

    eventLogoWidth = eventLogoMaxWidth;
  }

  let gatherlyWidth = sponsorsWidth / 3 - PADDING_SIZE;

  const logo = images['logo'];
  const logoFontName = 'Lato';
  const logoFontSize = 14;
  const logoFontWeight = '700';
  const logoLineHeight = 16;

  const scaleFactor =
    (sponsorsHeight - SPONSORS_PADDING * 2 - logoLineHeight) / logo.height;
  const logoWidth = logo.width * scaleFactor;
  const logoHeight = logo.height * scaleFactor;
  const logoY = sponsorsY + (sponsorsHeight - logoHeight - logoLineHeight) / 2;
  const logoX = sponsorsWidth - SPONSORS_PADDING - logoWidth;

  ctx.font = `${logoFontWeight} ${logoFontSize}px ${logoFontName}`;
  ctx.fillStyle = '#4B4D58';
  ctx.textBaseline = 'top';

  ctx.fillText('HOSTED BY:', logoX, logoY);

  ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight);

  gatherlyWidth = logoWidth;

  const sponsorsCenterX =
    SPONSORS_PADDING +
    eventLogoWidth +
    (sponsorsWidth - eventLogoWidth - gatherlyWidth - SPONSORS_PADDING * 2) / 2;
  const sponsorsCenterY = sponsorsY + sponsorsHeight / 2;
  const hashtag = '#GatherlyEvents';
  const fontName = 'Lato';
  const fontSize = 30;
  const fontWeight = '700';

  ctx.font = `${fontWeight} ${fontSize}px ${fontName}`;
  ctx.fillStyle = '#004461';
  ctx.textBaseline = 'middle';
  ctx.textAlign = 'center';

  ctx.fillText(hashtag, sponsorsCenterX, sponsorsCenterY);
}

interface ICaptureMoment {
  photo: IPhoto;
  eventInfo: AugmentedBetterDmvEvent | null;
  building: IBuilding | null;
  me: IUser | null;
  floor: IFloor | null;
  huddles: IHuddle[];
  huddlesMembers: Dictionary<IUser[]>;
}

export async function captureMoment({
  photo,
  eventInfo,
  building,
  me,
  floor,
  huddles,
  huddlesMembers,
}: ICaptureMoment): Promise<IPhoto> {
  try {
    Logger.debug('photo:captureMoment', 'init');

    const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    const canvasWidth = maxContentWidth;
    const canvasHeight = maxContentHeight;
    const mainContentWidth = canvasWidth;
    const mainContentHeight = canvasHeight - SPONSORS_HEIGHT;
    const tileGridX = PADDING_SIZE;
    const tileGridY = PADDING_SIZE;
    const tileGridWidth =
      Math.ceil((56 * mainContentWidth) / 100) - PADDING_SIZE * 2;
    const tileGridHeight = mainContentHeight - PADDING_SIZE * 2;
    const mapX = tileGridWidth + PADDING_SIZE * 2;
    const mapY = PADDING_SIZE * 2;
    const mapWidth = mainContentWidth - tileGridWidth - PADDING_SIZE * 4;
    const mapHeight = tileGridHeight - PADDING_SIZE * 2;
    const sponsorsX = 0;
    const sponsorsY = mainContentHeight;
    const sponsorsWidth = canvasWidth;
    const sponsorsHeight = SPONSORS_HEIGHT;

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    if (!ctx) throw new Error('ctx cannot be empty');

    await loadFonts();

    const imagesObjects = await loadImages(logoSvg, floor?.mapUrl);

    if (imagesObjects === undefined) {
      throw new Error('images not loaded');
    }

    Logger.debug('photo:loadImages', 'loading images success');

    const images: { [key: string]: HTMLImageElement } = {};

    imagesObjects.forEach(obj => {
      images[obj.name] = obj.elm;
    });

    const { eventHost, eventName, date } = getEventInfo(eventInfo);

    const names: { [key: string]: string } = {
      [photo.photographerId]: me?.name ?? '',
    };

    if (photo.huddleId) {
      const members = huddlesMembers[photo.huddleId];

      for (const member of members) {
        names[member.id] = member.name;
      }
    }

    Logger.debug('photo:captureMoment', 'render map init');

    drawMap({
      ctx,
      x: mapX,
      y: mapY,
      width: mapWidth,
      height: mapHeight,
      huddleId: photo.huddleId,
      me,
      huddles,
      huddlesMembers,
      floorMap: images['map'],
      floorName: floor?.name ?? 'Gatherly Space',
    });

    Logger.debug('photo:captureMoment', 'render map success');

    const map = ctx.getImageData(mapX, mapY, mapWidth, mapHeight);

    setFillStyleToLogoGradient(ctx, 0, mainContentHeight, mainContentWidth, 0);

    ctx.fillRect(0, 0, mainContentWidth, mainContentHeight);

    ctx.putImageData(map, mapX, mapY);

    drawTiles(
      ctx,
      tileGridX,
      tileGridY,
      tileGridWidth,
      tileGridHeight,
      JSON.parse(photo.participantsIds),
      names,
      images,
      eventName,
      date,
    );

    ctx.fillStyle = COLOR_WHITE;
    ctx.fillRect(sponsorsX, sponsorsY, sponsorsWidth, sponsorsHeight);

    await drawSponsors(
      ctx,
      sponsorsX,
      sponsorsY,
      sponsorsWidth,
      sponsorsHeight,
      building,
      eventHost,
      images,
    );

    const output = canvas.toDataURL('image/png');

    Logger.debug('photo', 'success');

    return {
      ...photo,
      output,
      status: PhotoStatus.FULFILLED,
    };
  } catch (e) {
    Logger.warn('photo:drawVideoTiles', e);

    return {
      ...photo,
      status: PhotoStatus.REJECTED,
    };
  }
}
