import { Message } from '.';

export interface IBuilding {
  id: string;
  chimeMediaRegion: string;
  passwordProtected: boolean;
  logoUrl: string | null;
  fieldDefinitions: FieldDefinition[];
  announcements: Message[];
  endingAt: number;
  emailSettings: EmailSettings;
}

export interface FieldDefinition {
  field: string;
  formatted: string;
  required: boolean;
}

export enum EmailSettings {
  DISABLED = 'disabled',
  OPTIONAL = 'optional',
  REQUIRED = 'required',
}
