import React from 'react';
import {
  Tooltip as MaterialTooltip,
  TooltipProps as MaterialTooltipProps,
} from '@material-ui/core';
import { c } from '../../utils';
import classes from './Tooltip.module.scss';

export interface TooltipProps
  extends Omit<MaterialTooltipProps, 'title' | 'classes'> {
  label?: NonNullable<React.ReactNode>;
  popperClassName?: string;
  tooltipClassName?: string;
}

export function Tooltip({
  label,
  popperClassName,
  tooltipClassName,
  children,
  enterDelay = 250,
  arrow = true,
  ...restProps
}: TooltipProps) {
  return (
    <MaterialTooltip
      title={label ?? ''}
      enterDelay={enterDelay}
      arrow={arrow}
      classes={{
        popper: popperClassName,
        tooltip: c(classes.tooltip, tooltipClassName),
        arrow: classes.arrow,
      }}
      {...restProps}
    >
      {children}
    </MaterialTooltip>
  );
}
