import React, { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { BasicPageLayout } from '../../layouts/BasicPageLayout';
import { ANIM_DURATION } from '../../styles';
import { AdditionalInfo } from './AdditionalInfo';
import { BasicInfo } from './BasicInfo';
import classes from './ProfileSetupPage.module.scss';
import { IUser } from '@poormanvr/common';

export function ProfileSetupPage(): JSX.Element {
  const [user, setUser] = useState<IUser | null>(null);

  return (
    <BasicPageLayout banner footer>
      <SwitchTransition>
        <CSSTransition
          key={user ? 'additional' : 'basic'}
          timeout={ANIM_DURATION}
          unmountOnExit
          classNames={classes}
        >
          <div className={classes.ProfileSetupPage}>
            <div className={classes.title} data-cy="header">
              {user
                ? 'More about you'
                : "Time to join the event - let's get started!"}
            </div>
            {user ? (
              <AdditionalInfo classes={classes} user={user} />
            ) : (
              <BasicInfo classes={classes} onDone={user => setUser(user)} />
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </BasicPageLayout>
  );
}
