import { Falsy, Point } from '../misc';
import {
  ELEVATOR_HEIGHT,
  ELEVATOR_WIDTH,
  EXIT_HEIGHT,
  EXIT_WIDTH,
  MAP_HEIGHT,
  MAP_WIDTH,
} from '../configs';
import { Diff, Json, JsonArray, JsonDict } from '../interfaces';

export * from './time';

export function isValidHttpUrl(string: string): boolean {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export function isValidHexCode(string: string): boolean {
  return /^#[0-9a-f]{3,6}$/i.test(string);
}

export function unique<T>(values: T[]): T[] {
  return [...new Set(values)];
}

export function clone<T>(obj: T): T {
  if (obj === undefined) return obj;
  return JSON.parse(JSON.stringify(obj));
}

export function isInElevator({ x, y }: Point): boolean {
  return x <= ELEVATOR_WIDTH && y >= MAP_HEIGHT - ELEVATOR_HEIGHT;
}

export function isInExit({ x, y }: Point): boolean {
  return x >= MAP_WIDTH - EXIT_WIDTH && y >= MAP_HEIGHT - EXIT_HEIGHT;
}

export function getDistance(point1: Point, point2: Point): number {
  const dx = point1.x - point2.x;
  const dy = point1.y - point2.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export function getMovingDuration(from: Point, to: Point) {
  return getDistance(from, to) * 12;
}

export function takeAveragePoint(points: Point[]): Point {
  let sumX = 0;
  let sumY = 0;
  points.forEach(({ x, y }) => {
    sumX += x;
    sumY += y;
  });
  const x = sumX / points.length;
  const y = sumY / points.length;
  return { x, y };
}

export function getHuddleRadius(size: number): number {
  return 10 + Math.log(Math.E + size) * 5;
}

export function isDict(object: unknown): object is JsonDict {
  return (
    object !== null && typeof object === 'object' && !Array.isArray(object)
  );
}

export const isArray = Array.isArray.bind(Array);

export function diffJson<T extends Json>(oldValue: T, newValue: T): Diff<T> {
  if (oldValue === newValue) return undefined;
  if (isDict(oldValue) && isDict(newValue)) {
    const oldDict = oldValue as JsonDict;
    const newDict = newValue as JsonDict;
    let different = false;
    const diffDict: Diff<JsonDict> = {};
    const keys = unique([...Object.keys(oldDict), ...Object.keys(newDict)]);
    for (const key of keys) {
      const diff = diffJson(oldDict[key], key in newDict ? newDict[key] : null);
      if (diff === undefined) continue;
      different = true;
      diffDict[key] = diff;
    }
    if (!different) return undefined;
    return diffDict as Diff<T>;
  }
  if (isArray(oldValue) && isArray(newValue)) {
    const oldArray = oldValue as Json[];
    const newArray = newValue as Json[];
    const different =
      oldArray.length !== newArray.length ||
      oldArray.some((v, i) => v !== newArray[i]);
    if (!different) return undefined;
    return newArray as Diff<T>;

    // deep comparing arrays has been disabled because diffJson([1], [1, 2]) => [undefined, 2], but it stringifies to [null, 2]
    // it can degrade frontend performance if an array has non-primitive values (we currently only have primitive values tho)
    // TODO: re-enable deep comparing arrays

    // const diffArray = newArray.map((newValue, i) => {
    //   const oldValue = oldArray[i];
    //   return diffJson(oldValue, newValue);
    // });
    // if (
    //   oldArray.length === newArray.length &&
    //   diffArray.every(el => el === undefined)
    // ) {
    //   return undefined;
    // }
    // return diffArray as Diff<T>;
  }
  return newValue;
}

export function patchJson<T extends Json>(oldValue: T, diffValue: Diff<T>): T {
  if (diffValue === undefined) return oldValue;
  if (isDict(oldValue) && isDict(diffValue)) {
    const oldDict = oldValue as JsonDict;
    const diffDict = diffValue;
    const newDict: JsonDict = {};
    const keys = unique([...Object.keys(oldDict), ...Object.keys(diffDict)]);
    for (const key of keys) {
      const newValue = patchJson(oldDict[key], diffDict[key]);
      if (newValue === null) continue;
      newDict[key] = newValue;
    }
    return newDict as T;
  }
  if (isArray(oldValue) && isArray(diffValue)) {
    const oldArray = oldValue as JsonArray;
    const diffArray = diffValue;
    const newArray = diffArray.map((diffValue, i) => {
      const oldValue = oldArray[i];
      return patchJson(oldValue, diffValue);
    });
    return newArray as T;
  }
  return diffValue as T;
}

export const async = (callback: () => void) => setTimeout(callback, 0);

export function isSamePoint(p1: Point, p2: Point) {
  return p1.x === p2.x && p1.y === p2.y;
}

const agoraContentUidPrefix = '12345';

export function createAgoraContentUid(userId: string) {
  return +(agoraContentUidPrefix + userId);
}

export function isAgoraUidContent(uid: number | string) {
  if (typeof uid !== 'string') {
    uid = String(uid);
  }

  return uid.startsWith(agoraContentUidPrefix);
}

export function getUserIdFromAgoraContentUid(uid: number | string) {
  if (typeof uid !== 'string') {
    uid = String(uid);
  }

  return uid.slice(agoraContentUidPrefix.length);
}

/**
 * Read environment variable.
 */
export function readEnv(
  key: string,
  defaultValue: number,
  isRequired?: boolean,
): number;
export function readEnv(
  key: string,
  defaultValue: boolean,
  isRequired?: boolean,
): boolean;
export function readEnv(
  key: string,
  defaultValue: string,
  isRequired?: boolean,
): string;
export function readEnv(
  key: string,
  defaultValue: any,
  isRequired?: boolean,
): never;
export function readEnv(
  key: string,
  defaultValue: unknown,
  isRequired?: boolean,
) {
  const value = process.env[key]?.trim();
  const isAbsent = !value;

  if (isRequired && isAbsent) {
    console.error(`Environment variable '${key}' must be provided.`);
    process.exit(1);
  } else if (isAbsent && process.env.NODE_ENV !== 'test') {
    console.warn(
      `${key} does not exist in this environment. [fallback: ${defaultValue}]`,
    );
  }

  switch (typeof defaultValue) {
    case 'number':
      if (!value || isNaN(+value)) return defaultValue;
      return +value;
    case 'boolean':
      if (!value) return defaultValue;
      return value.toLowerCase() === 'true';
    case 'string':
      return value || defaultValue;
  }
}

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function truthy<T>(value: T | Falsy): value is T {
  return Boolean(value);
}
