import React, { useCallback } from 'react';
import { GetGatherlyDialog } from '../../dialogs/GetGatherlyDialog';
import { useOverlay } from '../../hooks/useOverlay';
import { c, EventTracker } from '../../utils';
import { Button } from '../Button';
import classes from './GetGatherly.module.scss';
import { TrackerEvent } from '@poormanvr/common';

interface Props {
  disabled?: boolean;
  classNames?: string;
  onClick?: () => void;
}

export function GetGatherly({
  disabled,
  classNames,
  onClick,
}: Props): JSX.Element {
  const [, toggleGetGatherlyDialog] = useOverlay(
    () => <GetGatherlyDialog />,
    [],
  );

  const handleClick = useCallback(() => {
    EventTracker.trackEvent(TrackerEvent.Type.GETGATHERLY__INIT);
    toggleGetGatherlyDialog(true);
    onClick?.();
  }, [onClick, toggleGetGatherlyDialog]);

  return (
    <Button
      data-cy="get-gatherly-button"
      className={c(classes.GetGatherly, classNames)}
      onClick={handleClick}
      disabled={disabled}
    >
      Get Gatherly!
    </Button>
  );
}
