import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export default function GatherlyLogoIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 2.7C0 1.20883 1.20845 0 2.69916 0H16.8547C18.3454 0 19.5539 1.20883 19.5539 2.7V4.32L21.2933 2.19V2.19005C21.4925 1.91724 21.8146 1.74 22.1781 1.74C22.7827 1.74 23.2727 2.23025 23.2727 2.835V13.335C23.2727 13.9398 22.7827 14.43 22.1781 14.43C21.8146 14.43 21.4925 14.2528 21.2933 13.98L19.5539 11.85V13.47C19.5539 14.9611 18.3454 16.17 16.8547 16.17H11.3365V16.1701C11.0107 16.1746 10.7202 16.3255 10.5279 16.56H10.5254L4.85846 23.55C4.65937 23.8228 4.33726 24 3.97376 24C3.3692 24 2.8791 23.5098 2.8791 22.905C2.8791 22.7497 2.91139 22.602 2.96964 22.4683L4.51558 17.6926C4.58161 17.554 4.61856 17.3988 4.61856 17.235C4.61856 16.6468 4.14189 16.17 3.55389 16.17C3.54889 16.17 3.54388 16.17 3.53889 16.1701V16.17H2.69916C1.20845 16.17 0 14.9611 0 13.47V2.7ZM16.8553 3.9902C17.5676 3.9902 18.1449 3.41264 18.1449 2.7002C18.1449 1.98775 17.5676 1.4102 16.8553 1.4102C16.1431 1.4102 15.5657 1.98775 15.5657 2.7002C15.5657 3.41264 16.1431 3.9902 16.8553 3.9902Z"
        fill={props.fill ?? 'url("#logoGradient")'}
      />
      <defs>
        <linearGradient
          id="logoGradient"
          x1="23.2727"
          y1="-1.62587e-06"
          x2="-4.20342"
          y2="6.66088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#03CAA5" />
          <stop offset="1" stop-color="#00A9F2" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
