import React from 'react';
import { Clickable } from '../../components/Clickable';
import { GATHERLY_LOGO_URL } from '../../config';
import classes from './Footer.module.scss';

export function Footer(): JSX.Element {
  return (
    <footer className={classes.Footer}>
      <div className={classes.wrapper}>
        <Clickable href="https://gatherly.io/">
          <img
            src={GATHERLY_LOGO_URL}
            alt="Gatherly logo"
            className={classes.logo}
          />
        </Clickable>
        <div className={classes.links}>
          <div className={classes.linkGroup}>
            <h5 className="subheading-3">About Us</h5>
            <Clickable inline href="https://www.gatherly.io/blog">
              Blog
            </Clickable>
            <Clickable inline href="https://help.gatherly.io/">
              Help Center
            </Clickable>
          </div>
          <div className={classes.linkGroup}>
            <h5 className="subheading-3">Contact Us</h5>
            <Clickable inline href="https://www.gatherly.io/feedback">
              Feedback
            </Clickable>
            <Clickable inline href="https://gatherly.breezy.hr/">
              Hiring
            </Clickable>
          </div>
        </div>
      </div>
    </footer>
  );
}
