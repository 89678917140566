interface GridLayout {
  bestColSize: number; // number of columns in the grid
  bestRowSize: number; // number of rows in the grid
  itemHeight: number;
  itemMaxWidth: number; // allows us to crop sides if necessary
}

export function calcLayout(
  containerWidth: number,
  containerHeight: number,
  itemsCount: number,
  idealAspectRatio: number,
  maxAspectRatio: number,
): GridLayout {
  let minRatioDiff = Number.MAX_SAFE_INTEGER;
  let bestColSize = -1;
  for (let colSize = 1; colSize <= itemsCount; colSize++) {
    const rowSize = Math.ceil(itemsCount / colSize);
    const width = containerWidth / colSize;
    const height = containerHeight / rowSize;
    const ratio = width / height;
    const ratioDiff = Math.abs(ratio - idealAspectRatio);
    if (minRatioDiff > ratioDiff) {
      minRatioDiff = ratioDiff;
      bestColSize = colSize;
    }
  }
  const bestRowSize = Math.ceil(itemsCount / bestColSize);
  const itemHeight = Math.floor(containerHeight / bestRowSize);
  const itemMaxWidth = Math.floor(itemHeight * maxAspectRatio);

  return {
    bestColSize,
    bestRowSize,
    itemHeight,
    itemMaxWidth,
  };
}

export function getRowIndex(index: number, columns: number, rows: number) {
  const rowIndex = Math.floor(index / columns);
  if (rowIndex < rows && rowIndex >= 0) return rowIndex;
  return -1;
}

export function getColumnIndex(index: number, columns: number, rows: number) {
  if (index < 0) return -1;
  if (index >= columns * rows) return -1;
  return index % columns;
}

export function getCellOrigin(
  rowIndex: number,
  columnIndex: number,
  rowHeight: number,
  columnWidth: number,
  gutterSize: number,
) {
  const x = columnIndex * columnWidth;
  const xOffset = columnIndex * gutterSize;
  const y = rowIndex * rowHeight;
  const yOffset = rowIndex * gutterSize;
  return [x + xOffset, y + yOffset];
}
