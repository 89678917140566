import browserNotSupported from './assets/browser_not_supported.png';
import deviceNotSupportedMobile from './assets/device_not_supported_mobile.png';
import deviceNotSupportedTablet from './assets/device_not_supported_tablet.png';
import logoFull from './assets/logo_full.png';
import sentimentSatisfied from './assets/sentiment_satisfied.svg';
import sentimentNeutral from './assets/sentiment_neutral.svg';
import sentimentDissatisfied from './assets/sentiment_dissatisfied.svg';
import availableTickets from './assets/available_tickets.svg';
import reservedTickets from './assets/reserved_tickets.svg';
import charactersLeft from './assets/characters_left.svg';
import charactersRight from './assets/characters_right.svg';
import linkedIn from './assets/LinkedIn.png';
import twitter from './assets/Twitter.png';
import facebook from './assets/Facebook.png';
import checkBanner from './assets/check_banner.png';

export const Images = {
  BROWSER_NOT_SUPPORTED: {
    src: browserNotSupported,
    alt: 'A cartoon browser with a sad face',
  },
  DEVICE_NOT_SUPPORTED_MOBILE: {
    src: deviceNotSupportedMobile,
    alt: 'A cartoon smartphone with a sad face',
  },
  DEVICE_NOT_SUPPORTED_TABLET: {
    src: deviceNotSupportedTablet,
    alt: 'A cartoon tablet with a sad face',
  },
  LOGO_FULL: {
    src: logoFull,
    alt: 'Gatherly logo',
  },
  SENTIMENT_DISSATISFIED: {
    src: sentimentDissatisfied,
    alt: 'Unsatisfactory',
  },
  SENTIMENT_NEUTRAL: {
    src: sentimentNeutral,
    alt: 'Ok',
  },
  SENTIMENT_SATISFIED: {
    src: sentimentSatisfied,
    alt: 'Satisfactory',
  },
  AVAILABLE_TICKETS: {
    src: availableTickets,
    alt: 'Available Tickets',
  },
  RESERVED_TICKETS: {
    src: reservedTickets,
    alt: 'Reserved Tickets',
  },
  CHARACTERS_LEFT: {
    src: charactersLeft,
    alt: 'Characters Left',
  },
  CHARACTERS_RIGHT: {
    src: charactersRight,
    alt: 'Characters Right',
  },
  LINKED_IN: {
    src: linkedIn,
    alt: 'LinkedIn',
  },
  TWITTER: {
    src: twitter,
    alt: 'Twitter',
  },
  FACEBOOK: {
    src: facebook,
    alt: 'Facebook',
  },
  CHECK_BANNER: {
    src: checkBanner,
    alt: 'Check Banner',
  },
};
