export enum Type {
  TEST_TRACKER = 'test_tracker',
  GETGATHERLY__INIT = 'GetGatherly__init', // User clicks the 'Get Gatherly button
  GETGATHERLY__SUBMIT = 'GetGatherly__submit', // User submits a request to get Gatherly.
  PHOTOBOOTH__INIT = 'PhotoBooth__init', // User clicks the button
  PHOTOBOOTH__SELFIE = 'PhotoBooth__selfie', // User chooses to take a selfie
  PHOTOBOOTH__GROUP = 'PhotoBooth__group', // User chooses to take a group photo
  PHOTOBOOTH__ACCEPT = 'PhotoBooth__accept', // User accepts to take a group photo
  PHOTOBOOTH__REJECT = 'PhotoBooth__reject', // User rejects to take a group photo
  PHOTOBOOTH__CANCEL = 'PhotoBooth__cancel', // User cancels any time before photo is taken
  PHOTOBOOTH__SHARE = 'PhotoBooth__share', // User downloads or shares photo
}

export interface Options
  extends Record<
    Type,
    { [key: string]: string | number | boolean } | undefined
  > {
  [Type.TEST_TRACKER]: {
    value: string;
  };
  [Type.GETGATHERLY__INIT]: undefined;
  [Type.GETGATHERLY__SUBMIT]: undefined;
  [Type.PHOTOBOOTH__INIT]: undefined;
  [Type.PHOTOBOOTH__SELFIE]: undefined;
  [Type.PHOTOBOOTH__GROUP]: {
    participants: number;
  };
  [Type.PHOTOBOOTH__ACCEPT]: undefined;
  [Type.PHOTOBOOTH__REJECT]: undefined;
  [Type.PHOTOBOOTH__CANCEL]: undefined;
  [Type.PHOTOBOOTH__SHARE]: {
    participants: number;
    mode: string;
  };
}
