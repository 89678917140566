import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import { Dialog } from '../../components/Dialog';
import GatherlyLogoIcon from '../../icons/GatherlyLogoIcon';
import { BuildingContext } from '../../contexts/BuildingContext';
import { Button } from '../../components/Button';
import { c, EventTracker, handleRequestError } from '../../utils';
import { GET_GATHERLY_FORM_ID, GET_GATHERLY_PORTAL_ID } from '../../config';
import classes from './GetGatherlyDialog.module.scss';
import { TrackerEvent } from '@poormanvr/common';

interface Props {
  onClose?: () => void;
}

export function GetGatherlyDialog(props: Props): JSX.Element {
  const [done, setDone] = useState(false);

  const { eventInfo } = useContext(BuildingContext);

  const { error } = useHubspotForm({
    portalId: GET_GATHERLY_PORTAL_ID,
    formId: GET_GATHERLY_FORM_ID,
    target: '#hubspot',
    cssClass: classes.form,
    submitButtonClass: classes.button,
    errorMessageClass: classes.error,
  });

  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      if (event.data.type === 'hsFormCallback') {
        if (event.data.eventName === 'onFormReady') {
          const form = document.forms.namedItem(
            `hsForm_${GET_GATHERLY_FORM_ID}`,
          );

          if (form?.elements) {
            if (form?.event) {
              form.event.value = eventInfo?.name;
            }
          }
        } else if (event.data.eventName === 'onFormSubmitted') {
          EventTracker.trackEvent(TrackerEvent.Type.GETGATHERLY__SUBMIT);

          setDone(true);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [eventInfo?.name, setDone]);

  useEffect(() => {
    if (error) {
      handleRequestError('Hubspot error.');
    }
  }, [error]);

  const { onClose } = props;

  const handleDone = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Dialog
      className={c(classes.GetGatherlyDialog, done && classes.small)}
      classes={classes}
      {...props}
      icon={GatherlyLogoIcon}
      title="Get Gatherly"
    >
      <div id="hubspot"></div>

      {done && (
        <div className={classes.done}>
          <Button primary onClick={handleDone}>
            DONE
          </Button>
        </div>
      )}
    </Dialog>
  );
}
