import React from 'react';
import TextField from '@material-ui/core/TextField';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField/TextField';
import { c } from '../../utils';
import classes from './TextInput.module.scss';

type Props = Omit<OutlinedTextFieldProps, 'variant'>;

export function TextInput({ className, ...restProps }: Props) {
  return (
    <TextField
      variant="outlined"
      className={c(classes.TextInput, className)}
      {...restProps}
    />
  );
}
