import React, { Suspense, useContext, useMemo, useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { Loading } from '../Loading';
import { SERVER_MODE } from '../../config';
import { ANIM_DURATION } from '../../styles';
import { BuildingContext } from '../../contexts/BuildingContext';
import { NotSupported } from '../../pages/NotSupported';
import { NoEvent } from '../../pages/NoEvent';
import { LandingPage } from '../../pages/LandingPage';
import { IS_BROWSER_SUPPORTED, IS_DEVICE_SUPPORTED } from '../../utils/device';
import { EventStatus } from '../../types/eventStatus';
import { ProfileSetupPage } from '../../pages/ProfileSetupPage';
import { PermissionsPage } from '../../pages/PermissionsPage';
import classes from './App.module.scss';
import { ServerMode } from '@poormanvr/common';

const MainPage = React.lazy(() => import('../../pages/MainPage'));
const DeviceSetupPage = React.lazy(() => import('../../pages/DeviceSetupPage'));
const CheckingHub = React.lazy(() => import('../../pages/CheckingHub'));
const PreviewServer = React.lazy(() => import('../../pages/PreviewServer'));

export function App() {
  const { myId, building, eventStatus, eventInfo } = useContext(
    BuildingContext,
  );

  const profileSetup = Boolean(myId);
  const [isSupported, setIsSupported] = useState(
    IS_BROWSER_SUPPORTED && IS_DEVICE_SUPPORTED,
  );
  const [permissionSetup, setPermissionSetup] = useState(false);
  const [deviceSetup, setDeviceSetup] = useState(false);

  const node = useMemo(() => {
    if (SERVER_MODE === ServerMode.CHECK) {
      return <CheckingHub />;
    }

    if (eventStatus === EventStatus.FETCHING) {
      return <Loading message="Fetching event info ..." />;
    }

    if (eventStatus === EventStatus.NOT_FOUND) {
      return <NoEvent />;
    }

    if (eventStatus !== EventStatus.CURRENT && eventInfo) {
      return <LandingPage eventInfo={eventInfo} />;
    }

    if (!isSupported) {
      return <NotSupported onSkip={() => setIsSupported(true)} />;
    }
    if (!building) {
      return <Loading message="Fetching building info ..." />;
    }
    if (SERVER_MODE === ServerMode.PREVIEW) {
      return <PreviewServer />;
    }
    if (!profileSetup) {
      return <ProfileSetupPage />;
    }
    if (!permissionSetup) {
      return (
        <PermissionsPage
          key="device"
          onSubmit={() => setPermissionSetup(true)}
        />
      );
    }
    if (!deviceSetup) {
      return (
        <DeviceSetupPage key="device" onSubmit={() => setDeviceSetup(true)} />
      );
    }
    return <MainPage />;
  }, [
    isSupported,
    building,
    profileSetup,
    permissionSetup,
    deviceSetup,
    eventStatus,
    eventInfo,
  ]);

  return (
    <div className={classes.App}>
      <SwitchTransition>
        <CSSTransition
          key={node.key ?? node.type}
          timeout={ANIM_DURATION}
          unmountOnExit
          classNames={classes}
        >
          <Suspense fallback={<Loading message="Loading ..." />}>
            {node}
          </Suspense>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}
