import { MicRounded, VideocamRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { Button } from '../../components/Button';
import { BasicPageLayout } from '../../layouts/BasicPageLayout';
import { PermissionsOverlay } from './PermissionsOverlay';
import classes from './PermissionsPage.module.scss';

interface Props {
  onSubmit: () => void;
}

export function PermissionsPage({ onSubmit }: Props): JSX.Element {
  const [gotIt, setGotIt] = useState(false);

  useEffect(() => {
    const requestPermission = () => {
      return window.setTimeout(() => {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: true,
          })
          .then(() => onSubmit())
          .catch(e => {
            console.error(e);
            if (
              ['NotAllowedError', 'PermissionDismissedError'].includes(e.name)
            ) {
              handle = requestPermission();
            } else {
              window.alert(e);
              onSubmit();
            }
          });
      }, 1000);
    };

    let handle = requestPermission();

    return () => {
      window.clearTimeout(handle);
    };
  }, [onSubmit]);

  return (
    <BasicPageLayout>
      <div className={classes.PermissionsPage}>
        <div className={classes.title}>Audio and video permissions</div>
        <p>
          <strong>Don't see the browser pop-up?</strong>
          <br />
          Click the <VideocamRounded className={classes.icon} /> or{' '}
          <MicRounded className={classes.icon} /> icon located in the left or
          right side of the address bar above.
        </p>

        {gotIt && (
          <Button className={classes.button} onClick={onSubmit}>
            Proceed without them
          </Button>
        )}
      </div>

      <TransitionGroup component={null}>
        <PermissionsOverlay onGotIt={() => setGotIt(true)} />
      </TransitionGroup>
    </BasicPageLayout>
  );
}
