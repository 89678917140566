import { ArrowBack } from '@material-ui/icons';
import React, {
  useCallback,
  useState,
  KeyboardEvent,
  useEffect,
  useRef,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { Button } from '../../components/Button';
import { ANIM_DURATION } from '../../styles';
import classes from './PermissionsOverlay.module.scss';

interface Props {
  onGotIt: () => void;
}

export function PermissionsOverlay({ onGotIt }: Props): JSX.Element {
  const [mounted, setMounted] = useState(true);
  const mainRef = useRef<HTMLDivElement>(null);

  const handleKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLDivElement>) => {
      if (evt.key === 'Escape' || evt.key === 'ESC') {
        setMounted(false);
        onGotIt();
      }
    },
    [onGotIt],
  );

  useEffect(() => {
    if (mainRef.current) {
      mainRef.current.focus();
    }
  }, [mainRef]);

  return (
    <CSSTransition
      in={mounted}
      timeout={ANIM_DURATION}
      classNames={classes}
      unmountOnExit
    >
      <div
        className={classes.PermissionsOverlay}
        onKeyDown={handleKeyDown}
        tabIndex={1}
        ref={mainRef}
      >
        <div className={classes.wrapper}>
          <div className={classes.pointer}>
            <ArrowBack className={classes.arrow} />
          </div>

          <div className={classes.content}>
            <div className={classes.instructions}>
              <div className={classes.allow}>
                Allow Gatherly to use your mic and camera
              </div>
              <br />
              To let others see and hear you, click "<b>Allow</b>" above.
            </div>
            <div className={classes.gotIt}>
              <Button
                primary
                className={classes.button}
                onClick={() => {
                  setMounted(false);
                  onGotIt();
                }}
              >
                Ok, got it
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
