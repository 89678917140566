import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { c } from '../../utils';
import classes from './Loading.module.scss';

interface Props {
  className?: string;
  message?: string;
  overlay?: boolean;
}

export function Loading({ className, message, overlay, ...restProps }: Props) {
  return (
    <div
      className={c(classes.Loading, overlay && classes.overlay, className)}
      {...restProps}
    >
      <CircularProgress />
      {message && <div className={classes.message}>{message}</div>}
    </div>
  );
}
