import { getSearchParam } from './utils';
import { MINUTE, readEnv, ServerMode } from '@poormanvr/common';

export { default as GATHERLY_LOGO_URL } from './assets/logo_narrow.svg';

export const VIDEO_IDEAL_ASPECT_RATIO = 1.4;
export const VIDEO_MAX_ASPECT_RATIO = 1.6;
export const GROUP_PHOTO_MIN_ASPECT_RATIO = 1;

export const TAG_COLORS: { [tag: string]: string } = {
  Networking: '#F4F4F8',
  Fun: '#E5E7F7',
  Social: '#CCEEFC',
  Booths: '#FBF6E7',
};

function getEventAlias(): string | null {
  if (process.env.NODE_ENV === 'development') {
    return getSearchParam('aliasName');
  }
  const regExp = /^([\w-]+)\.(event|stage)\.gatherly\.io$/i;
  const match = window.location.hostname?.match(regExp);
  return match ? match[1] : null;
}
export const EVENT_ALIAS = getEventAlias();

const [subdomain] = window.location.hostname.split('.');
export const EXIT_URL = `https://gatherly.io/feedback?utm_source=gatherly_event&utm_medium=exit_modal&utm_campaign=${subdomain}`;

export const TECH_SPEC_URL =
  'https://help.gatherly.io/en/articles/4665224-technical-specifications-for-gatherly';

export const CUSTOM_LIVESTREAM_SPEC_URL = `https://help.gatherly.io/en/articles/5129007-custom-livestream-specifications-for-gatherly`;
export const COPPA_WAIVER_URL =
  'https://www.waiverfile.com/b/gatherly/Waiver.aspx';

export const EMOJIS = ['👍', '👎', '❤️', '🎉'];

// NOTE: Might no longer need this, because no tutorial, but could be needed for testing
export const MOCK_VIDEO =
  'https://poormanvr.s3.us-east-2.amazonaws.com/tutorial-user-katara.gif';

export const SENTRY_DSN =
  'https://f9ab4e73e096491e833c96dd604dd603@o445939.ingest.sentry.io/5551502';

export const DEFAULT_BROCHURE_URL =
  'https://poormanvr.s3.us-east-2.amazonaws.com/brochure-pdf/default-brochure.pdf';

export const IS_LOCAL_DEV = process.env.NODE_ENV === 'development';
export const IS_TEST = process.env.NODE_ENV === 'test';

export const TECH_SPECS_URL =
  'https://help.gatherly.io/en/articles/4665224-technical-specifications-for-gatherly';

export const SMARTLOOK_KEY = '9274bba2afd0263e7c15f1aeceefd0be7993f955';

export const CHECKING_HUB_URL = 'https://check.gatherly.io';

export const PREVIEW_SERVER_URL = 'https://preview.gatherly.io';

const defaultServerMode = (() => {
  switch (window.location.origin) {
    case CHECKING_HUB_URL:
      return ServerMode.CHECK;
    case PREVIEW_SERVER_URL:
      return ServerMode.PREVIEW;
    default:
      return ServerMode.DEFAULT;
  }
})();

export const SERVER_MODE = readEnv(
  'REACT_APP_SERVER_MODE',
  defaultServerMode,
) as ServerMode;

export const EVENT_COUNTDOWN_DURATION = 5 * MINUTE;

export const TWITTER_SHARE_URL =
  'https://twitter.com/share?ref_src=twsrc%5Etfw';
export const LINKED_IN_SHARE_URL = 'https://www.linkedin.com/feed/';
export const FACEBOOK_SHARE_URL = 'https://www.facebook.com/';

export const GET_GATHERLY_FORM_ID = 'a5610463-9524-4540-a878-1383c6fb4984';
export const GET_GATHERLY_PORTAL_ID = '7747565';

export const HUBSPOT_FORM_API_URL =
  'https://api.hsforms.com/submissions/v3/integration/submit/';
export const OPT_IN_PORTAL_ID = '7747565';
export const OPT_IN_FORM_ID = 'e5bc5065-dca0-4bb9-a65b-da60f046d44c';

export const TROUBLESHOOTING_URL =
  'https://help.gatherly.io/en/articles/4665327-troubleshooting-guide-for-gatherly';

export const FAQ = [
  {
    id: 'platform',
    title: 'How do I move around the platform?',
    content:
      "In the top right corner of your screen, you'll find the Gatherly map. This map shows the location of everyone on your floor. Click anywhere on the map to move around.",
  },
  {
    id: 'conversation',
    title: 'How do I join or start a conversation?',
    content:
      'Circles on the map represent ongoing video chat conversations. Click one to walk over and join it. To start a conversation with someone, walk over to their avatar on the map.',
  },
  {
    id: 'floors',
    title: 'How do I change floors?',
    content:
      'Head to the Elevators at the bottom left corner of the map. Once you\'re there, click the "Join" button for any of the available floors to instantly arrive there.',
  },
  {
    id: 'event',
    title: 'Where can I learn more about the event?',
    content:
      'Click the "i" icon in the top right corner of your screen to view the PDF brochure that has been provided by the event host.',
  },
  {
    id: 'help',
    title: 'How can I get additional help?',
    content: `Check out our help guides on <a href="https://help.gatherly.io/en/collections/2634286-attending-a-gatherly-event" target="_blank" rel="noopener noreferrer">attending a Gatherly event</a>.`,
  },
];
