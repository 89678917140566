import React, {
  createContext,
  Dispatch,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';

import axios from 'axios';
import * as Sentry from '@sentry/react';
import { EVENT_ALIAS, SERVER_MODE } from '../config';
import { useRequest } from '../hooks/useRequest';
import {
  getUserParams,
  handleRequestError,
  EventTracker,
  getSearchParam,
  getEventStatus,
  Logger,
} from '../utils';
import { GetNearestChimeMediaRegion } from '../requests';
import { EventStatus } from '../types/eventStatus';

import {
  Dictionary,
  HttpMethod,
  IBuilding,
  IFloor,
  Request,
  EmailSettings,
  ServerMode,
  AugmentedBetterDmvEvent,
} from '@poormanvr/common';

interface Value {
  building: IBuilding | null;
  floors: Dictionary<IFloor>;
  myId: string | null;
  setMyId: Dispatch<string | null>;
  chimeMediaRegion: string | null;
  blankEmailField: '' | null;
  eventInfo: AugmentedBetterDmvEvent | null;
  eventStatus: EventStatus;
}

export const BuildingContext = createContext<Value>(null as any);

interface Props {
  children: ReactNode;
}

const DEFAULT_MY_ID = SERVER_MODE === ServerMode.PREVIEW ? '0000' : null;

export function BuildingProvider({ children }: Props) {
  const [building, setBuilding] = useState<IBuilding | null>(null);
  const [floors, setFloors] = useState<Dictionary<IFloor>>({});
  const [myId, setMyId] = useState<string | null>(null);
  const [chimeMediaRegion, setChimeMediaRegion] = useState<string | null>(null);

  const [isGetEventInfoRequesting, setGetInfoRequesting] = useState<boolean>(
    true,
  );
  const hasEventAlias = EVENT_ALIAS !== null;
  const [eventInfo, setEventInfo] = useState<AugmentedBetterDmvEvent | null>(
    null,
  );
  const eventStatus = useMemo<EventStatus>(() => {
    if (EVENT_ALIAS === null) {
      return EventStatus.CURRENT;
    }

    if (isGetEventInfoRequesting) {
      return EventStatus.FETCHING;
    }

    if (!eventInfo) {
      return EventStatus.NOT_FOUND;
    }

    return (
      (eventInfo && getEventStatus(eventInfo.startTime, eventInfo.stopTime)) ??
      EventStatus.NOT_FOUND
    );
  }, [eventInfo, isGetEventInfoRequesting]);

  const getEventInfo = useRequest<Request.GetEventInfo>(
    HttpMethod.GET,
    `https://${EVENT_ALIAS}.server.gatherly.io/api/event-info`,
  );

  const getBuilding = useRequest<Request.GetBuilding>(
    HttpMethod.GET,
    SERVER_MODE === ServerMode.PREVIEW
      ? `/api/preview/${getSearchParam('previewEventId')}`
      : '/api/building',
  );
  const updateUser = useRequest<Request.UpdateUser>(
    HttpMethod.PUT,
    '/api/user',
  );

  const getNearestChimeMediaRegion = useRequest<GetNearestChimeMediaRegion>(
    HttpMethod.GET,
    'https://nearest-media-region.l.chime.aws/',
  );

  const getCsrfToken = useRequest<Request.GetCsrfToken>(
    HttpMethod.GET,
    '/api/csrf-token',
  );

  useEffect(() => {
    async function checkDynamo() {
      if (SERVER_MODE === ServerMode.CHECK) return;

      if (EVENT_ALIAS !== null) {
        const eventInfo = await getEventInfo().catch(() => null);

        if (eventInfo) {
          setEventInfo(eventInfo);
        }
      }
      setGetInfoRequesting(false);
    }
    let interval: NodeJS.Timeout;
    if (EVENT_ALIAS !== null) {
      checkDynamo();
      // Updates every 5 minutes in case of change to start time
      interval = setInterval(() => checkDynamo(), 300000);
    } else {
      setGetInfoRequesting(false);
    }
    return () => {
      if (EVENT_ALIAS !== null) {
        clearInterval(interval);
      }
    };
  }, [getEventInfo]);

  useEffect(() => {
    const setCsrfHeaders = async (): Promise<void> => {
      const { csrfToken } = await getCsrfToken();
      axios.defaults.headers.post['X-XSRF-TOKEN'] = csrfToken;
      axios.defaults.headers.put['X-XSRF-TOKEN'] = csrfToken;
      axios.defaults.headers.delete['X-XSRF-TOKEN'] = csrfToken;
    };

    (async () => {
      if (SERVER_MODE === ServerMode.CHECK) {
        await setCsrfHeaders();
        return;
      }

      if (isGetEventInfoRequesting) return;
      if (hasEventAlias && eventStatus !== EventStatus.CURRENT) return;

      await setCsrfHeaders();

      const [buildingResp, regionResp] = await Promise.all([
        getBuilding(),
        getNearestChimeMediaRegion().catch(e => {
          Logger.error('BuildingContext:getNearestChimeMediaRegion', e);
          return null;
        }),
      ]);
      const { building, floors, user } = buildingResp;
      const chimeMediaRegion = regionResp?.region ?? building.chimeMediaRegion;

      let myId = user?.id ?? DEFAULT_MY_ID;
      if (user) {
        const userParams = getUserParams(floors, user);

        try {
          await updateUser({
            ...userParams,
            link: user.link,
            email: user.email,
            fields: user.fields,
            chimeMediaRegion,
            href: window.location.href,
          });
        } catch (e) {
          handleRequestError(e);
          myId = null;
        }
      }
      setBuilding(building);
      setFloors(floors);
      setMyId(myId);
      setChimeMediaRegion(chimeMediaRegion);
      EventTracker.identify(user, building);
    })().catch(handleRequestError);
  }, [
    getNearestChimeMediaRegion,
    getBuilding,
    updateUser,
    isGetEventInfoRequesting,
    eventStatus,
    hasEventAlias,
    getCsrfToken,
  ]);

  useEffect(() => {
    Sentry.setTag('buildingId', building?.id ?? '');
  }, [building?.id]);

  const blankEmailField =
    building?.emailSettings === EmailSettings.DISABLED ? null : '';

  const value = useMemo<Value>(
    () => ({
      building,
      floors,
      myId,
      setMyId,
      chimeMediaRegion,
      blankEmailField,
      eventStatus,
      eventInfo,
    }),
    [
      building,
      floors,
      myId,
      setMyId,
      chimeMediaRegion,
      blankEmailField,
      eventStatus,
      eventInfo,
    ],
  );

  return (
    <BuildingContext.Provider value={value}>
      {children}
    </BuildingContext.Provider>
  );
}
