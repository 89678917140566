import React from 'react';
import classes from './Checkbox.module.scss';

interface Props {
  label?: string;
  checked?: boolean;
  onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void;
}

export function Checkbox({ label, checked, onChange }: Props): JSX.Element {
  return (
    <label className={classes.Checkbox}>
      <input type="checkbox" onChange={onChange} checked={checked} />
      <span>{label}</span>
    </label>
  );
}
