import React, { useEffect } from 'react';

import sleeb from '../../assets/sleeb.gif';
import classes from './NoEvent.module.scss';

export const NoEvent: React.FC = () => {
  useEffect(() => {
    document.title = `Gatherly | 404 Landing Page`;
  });

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img className={classes.sleeb} src={sleeb} alt="404" />
        <br />
        <h4>Oops... looks like this event isn&apos;t active at the moment.</h4>
        <br />
        <p className="body1">
          If you&apos;re sure that the <strong>link</strong> and
          <strong> time</strong> are correct for the event you wish to join,
          this page may be cached. Click the button below to refresh this page,
          or try opening this link in&nbsp;
          <a
            href="https://support.google.com/chrome/answer/95464"
            target="_blank"
            rel="noreferrer"
          >
            {`Google Chrome's incognito mode`}
          </a>
          !
        </p>
        <br />
        <button
          onClick={() => window.location.reload(true)}
          type="button"
          className="btn btn-primary btn-lg"
        >
          Refresh
        </button>
      </div>
    </div>
  );
};
