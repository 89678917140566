export enum PhotoStatus {
  IDLE = 'IDLE', // No picture being taken
  SCHEDULED = 'SCHEDULED', // Picture has been scheduled
  PENDING = 'PENDING', // Rendering
  FULFILLED = 'FULFILLED', // Rendered
  REJECTED = 'REJECTED', // Rendering error
}

export interface IPhoto {
  id: string;
  scheduledAt: number;
  scheduledFor: number;
  huddleId?: string; // User might not be in a huddle
  photographerId: string;
  participantsIds: string;
  hasError?: boolean;
  status: PhotoStatus;
  output?: string;
}
