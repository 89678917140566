import React from 'react';
import { SvgIconComponent } from '@material-ui/icons';
import { c } from '../../utils';
import { Clickable } from '../Clickable';
import classes from './Button.module.scss';

interface Props extends React.ComponentProps<typeof Clickable> {
  primary?: boolean;
  negative?: boolean;
  icon?: SvgIconComponent;
}

export function Button({
  className,
  primary,
  negative,
  icon: IconComponent,
  disabled,
  children,
  ...restProps
}: Props) {
  return (
    <Clickable
      className={c(
        classes.Button,
        primary && classes.primary,
        negative && classes.negative,
        disabled && classes.disabled,
        className,
      )}
      disabled={disabled}
      {...restProps}
    >
      {IconComponent && <IconComponent className={classes.icon} />}
      {children}
    </Clickable>
  );
}
