import { Json } from './interfaces';

export enum SocketClosingCode {
  AUTH_FAILED = 4000,
  ANOTHER_SESSION,
  INACTIVE_SESSION,
  CONNECTION_TIMEOUT,
  LOGGED_OUT,
  SNAPSHOT_DIFF_MISSED,
  BANNED,
}

export interface Point {
  x: number;
  y: number;
}

export type Dictionary<T extends Json> = Record<string, T>;

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

export type Falsy = false | 0 | '' | null | undefined;

export enum ServerMode {
  DEFAULT = 'default', // main platform
  CHECK = 'check', // checking hub
  PREVIEW = 'preview', // preview server
}
