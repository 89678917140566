import bezierEasing from 'bezier-easing';
import { DateTime } from 'luxon';
import { mix } from 'color2k';
import { EMOJIS } from '../config';
import {
  COLOR_AVATAR_DEFAULT,
  COLOR_BLACK,
  COLOR_SHADE_20,
  COLOR_SHADE_80,
  COLOR_WHITE,
  MIX_WEIGHT_DARK,
  MIX_WEIGHT_LIGHT,
  MIX_WEIGHT_LIGHTER,
} from '../styles';
import { EventStatus } from '../types/eventStatus';
import { Dictionary, Falsy, IFloor, IUser, Point } from '@poormanvr/common';

export * as EventTracker from './eventTracker';
export * as Grid from './grid';
export * as Photo from './photo';
export * as Logger from './logger';

export function c(...classNames: (string | Falsy)[]) {
  return classNames.filter(v => v).join(' ');
}

const easing = bezierEasing(0.42, 0, 0.58, 1.0);

export function interpolate(
  from: number,
  to: number,
  timing: number,
  duration: number,
): number {
  const ratio = easing(Math.max(0, Math.min(1, timing / duration)));
  return (to - from) * ratio + from;
}

export function interpolatePoint(
  from: Point,
  to: Point,
  timing: number,
  duration: number,
): Point {
  return {
    x: interpolate(from.x, to.x, timing, duration),
    y: interpolate(from.y, to.y, timing, duration),
  };
}

export function createMatchKeyword(keyword: string) {
  const refine = (v: string) =>
    v
      .normalize('NFD') // decompose accents
      .replace(/[\u0300-\u036f]/g, '') // remove accents
      .replace(/\W/g, ' ') // replace non-word characters into spaces
      .trim() // trim white spaces
      .replace(/\s{2,}/g, ' ') // remove extra white spaces
      .toLowerCase();
  const refinedKeyword = refine(keyword);

  if (!refinedKeyword) return () => true;

  return (text: string) => {
    const refinedText = refine(text);
    if (new RegExp(`(^|\\W)${refinedKeyword}`).test(refinedText)) return true;
    const initials = refinedText
      .split(' ')
      .map(word => word[0])
      .join('');
    return initials.startsWith(refinedKeyword);
  };
}

export function ellipsis(text: string, maxLength = 20) {
  if (text.length <= maxLength) return text;
  return text.substring(0, maxLength - 2) + '...';
}

// type-safer way to look up dictionary
export function get<T>(
  dictionary: Dictionary<T>,
  id: string | null | undefined,
): T | null {
  if (!id) return null;
  return dictionary[id] ?? null;
}

const searchParams = new URLSearchParams(window.location.search);

export function getSearchParam(name: string): string | null {
  return searchParams.get(name);
}

export function getUserParams(floors: Dictionary<IFloor>, user?: IUser) {
  const searchParams = new URLSearchParams(window.location.search);

  const decodeString = (raw: string | null) => {
    if (!raw) return null;

    try {
      return atob(decodeURIComponent(raw));
    } catch {
      return null;
    }
  };
  const decodeJson = (raw: string | null) => {
    const decoded = decodeString(raw);
    if (!decoded) return null;
    try {
      return JSON.parse(decoded);
    } catch (e) {
      return null;
    }
  };
  const decodeNumber = (raw: string | null) => {
    const decoded = decodeString(raw);
    return decoded && !isNaN(+decoded) ? +decoded : null;
  };
  const findFloor = (floorNum: number) => {
    return Object.values(floors).find(floor => floor.num === floorNum);
  };

  const getBroadcast = () => {
    const rawBroadcast = searchParams.get('broadcast');
    return rawBroadcast === 'true';
  };

  const getUuid = () => {
    return searchParams.get('uuid');
  };

  const getName = () => {
    const rawName = searchParams.get('name');
    return decodeString(rawName) ?? user?.name ?? '';
  };

  const getColor = () => {
    const rawColor = searchParams.get('color');
    return decodeString(rawColor);
  };

  const getFloorId = () => {
    const rawFloorNum = searchParams.get('floorNum');
    const floorNum = decodeNumber(rawFloorNum) ?? 0;
    return user?.floorId ?? findFloor(floorNum)?.id ?? null;
  };

  const getAllowedFloorIds = () => {
    const rawAllowedFloors = searchParams.get('allowedFloors');
    const allowedFloorNums: number[] | null = decodeJson(rawAllowedFloors);
    if (!allowedFloorNums) return null;
    const allowedFloorIds: string[] = [];
    for (const floorNum of allowedFloorNums) {
      const floor = findFloor(floorNum);
      if (!floor) return null;
      allowedFloorIds.push(floor.id);
    }
    return allowedFloorIds;
  };

  const getTitle = () => {
    const rawTitle = searchParams.get('title');
    return decodeString(rawTitle) ?? user?.title ?? '';
  };

  const broadcast = getBroadcast();
  const uuid = getUuid();
  const name = getName();
  const color = getColor();
  let floorId = getFloorId();
  const allowedFloorIds = getAllowedFloorIds();
  const title = getTitle();

  if (allowedFloorIds && floorId) {
    if (!allowedFloorIds.includes(floorId)) {
      floorId = null;
    }
  }

  return { broadcast, uuid, name, color, floorId, allowedFloorIds, title };
}

if (process.env.NODE_ENV === 'development') {
  (window as any).encodeUserParam = (v: any) => encodeURIComponent(btoa(v));
}

export function mixDark(color: string | null) {
  if (!color || color === COLOR_AVATAR_DEFAULT) return COLOR_SHADE_80;
  return mix(COLOR_BLACK, color, MIX_WEIGHT_DARK);
}

export function mixLight(color: string | null) {
  return mix(COLOR_WHITE, color ?? COLOR_AVATAR_DEFAULT, MIX_WEIGHT_LIGHT);
}

export function mixLighter(color: string | null) {
  if (!color || color === COLOR_AVATAR_DEFAULT) return COLOR_SHADE_20;
  return mix(COLOR_WHITE, color, MIX_WEIGHT_LIGHTER);
}

export function handleRequestError(error: any) {
  window.alert(error?.message ?? error); // TODO: window.alert is bad
}

export function pluralize(
  number: number,
  singularUnit: string,
  pluralUnit = `${singularUnit}s`,
) {
  if (number === 0) return `No ${pluralUnit}`;
  if (number === 1) return `${number} ${singularUnit}`;
  return `${number} ${pluralUnit}`;
}

export function formatTime(at: number) {
  return DateTime.fromMillis(at).toFormat('h:mm a');
}

/**
 * Formats the start and end time properly for the "Add to Calendar"
 * button
 *
 * @return {string} date formatted in mm/dd/yyyy hh:mm AM/PM
 */
export function formatDate(unixTimeStamp: number) {
  return DateTime.fromMillis(unixTimeStamp).toFormat('MM/d/yyyy h:mm a');
}

/**
 * Returns the difference between the two arrays.
 */
export function diffArray<T>(oldArr: T[], newArr: T[]) {
  const oldDict = new Set(oldArr);
  const newDict = new Set(newArr);
  const added = newArr.filter(v => !oldDict.has(v));
  const removed = oldArr.filter(v => !newDict.has(v));
  return { added, removed };
}

export function isEmoji(value: string) {
  return EMOJIS.includes(value) || /^\p{Emoji_Presentation}$/u.test(value);
}

export function createVideoElement(options: {
  fit: 'contain' | 'cover';
  mirror?: boolean;
}) {
  const videoElement = document.createElement('video');
  videoElement.autoplay = true;
  videoElement.style.position = 'relative';
  videoElement.style.width = '100%';
  videoElement.style.height = '100%';
  videoElement.style.objectPosition = 'center';
  videoElement.style.objectFit = options.fit;
  videoElement.style.transform = options.mirror ? 'rotateY(180deg)' : '';
  return videoElement;
}

export const DEFAULT_BANNER_URL =
  // eslint-disable-next-line max-len
  'https://poormanvr.s3.us-east-2.amazonaws.com/betterDMV-banners/People+Banner.png';

export const getMonth = (date: Date) => {
  return DateTime.fromJSDate(date).toFormat('MMMM');
};

export const getDateTimeFromUnixTimestamp = (unixTimeStamp: number): string => {
  const date = DateTime.fromMillis(unixTimeStamp);
  return date.toFormat('h:mma MM/d ');
};

export const getTimeZone = () => {
  return DateTime.local().toFormat('ZZZZ');
};

export const delay = (ms: number): Promise<void> =>
  new Promise(res => setTimeout(res, ms));

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getEventStatus(
  startTime: number,
  stopTime: number,
  timestamp = Date.now(),
): EventStatus {
  if (stopTime < timestamp) {
    return EventStatus.PAST;
  } else if (startTime > timestamp) {
    return EventStatus.FUTURE;
  }
  return EventStatus.CURRENT;
}

export function getServerEndpoint() {
  const { protocol, hostname } = window.location;

  if (hostname === 'localhost') {
    return `${protocol}//${hostname}:3141`;
  }

  const [subdomain, ...domains] = hostname.split('.');

  if (domains.join('.') === 'gatherly.io') {
    return `${protocol}//${hostname}`;
  }

  if (domains[0] === 'stage') {
    return `${protocol}//${subdomain}.staging.gatherly.io`;
  }

  return `${protocol}//${subdomain}.server.gatherly.io`;
}

export function roundTo(raw: number, decimalDigits: number) {
  const k = 10 ** decimalDigits;
  return Math.round(raw * k) / k;
}

export function toListMap<T extends Record<string, any>>(
  arr: T[],
  key: string,
): Record<string, T> {
  return arr.reduce((memo: Record<string, T>, item: T) => {
    memo[item[key]] = item;
    return memo;
  }, {});
}

export function getColorName(hex: string | null, defaultColor = 'dark gray') {
  // the following is based on https://github.com/Gatherly/betterDMV/blob/production/src/components/Modals/LinkBuilder/LinkBuilder.js#L57
  switch (hex) {
    case '#002FAF':
      return 'dark blue';
    case '#0E584E':
      return 'dark green';
    case '#5E1DC8':
      return 'purple';
    case '#702800':
      return 'brown';
    case '#971414':
      return 'red';
    case '#8E117A':
      return 'dark pink';
    default:
      return defaultColor;
  }
}
