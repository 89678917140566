import React, {
  ReactNode,
  useLayoutEffect,
  useRef,
  MouseEvent,
  useCallback,
  KeyboardEvent,
} from 'react';
import { Close, SvgIconComponent } from '@material-ui/icons';
import { CSSTransition } from 'react-transition-group';
import * as focusTrap from 'focus-trap';
import { c } from '../../utils';
import { ANIM_DURATION } from '../../styles';
import { Button } from '../Button';
import { GridButton } from '../GridButton';
import classes from './Dialog.module.scss';
import { Falsy } from '@poormanvr/common';

type Props = React.HTMLProps<HTMLDivElement> &
  Partial<CSSTransition<HTMLElement>['props']> & {
    className?: string;
    classes?: { readonly [key: string]: string };
    icon: SvgIconComponent;
    title: string;
    children?: ReactNode;
    negative?: boolean;
    buttons?: {
      [label: string]: Falsy | (() => boolean | void);
    };
    primaryButtonIndex?: number;
    onClose?: () => void;
    // TODO: added this prop specifically for brochure dialog because focusTrap disables all the input outside the dialog container. ideally focusTrap should make everything behind the brochure dialog non-keyboard-navigable while any other elements still focusable.
    noFocusTrap?: boolean;
  };

export function Dialog({
  className,
  classes: customClasses = {},
  icon: IconComponent,
  title,
  children,
  negative,
  buttons,
  primaryButtonIndex = 0,
  onClose,
  noFocusTrap,
  in: in_,
  mountOnEnter,
  unmountOnExit,
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
  nodeRef,
  ...restProps
}: Props) {
  const backdropRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (noFocusTrap) return;
    const backdrop = backdropRef.current;
    if (!backdrop) return;
    const trap = focusTrap.createFocusTrap(backdrop, {
      escapeDeactivates: false,
      allowOutsideClick: true,
    });
    trap.activate();

    return () => {
      trap.deactivate();
    };
  }, [noFocusTrap]);

  // the following allows us to pass down mouse events, but still close the dialog when the backdrop is clicked
  const handleOverlayClick = useCallback(
    (evt: MouseEvent<HTMLDivElement, Event>) => {
      if (evt.target === backdropRef.current) {
        onClose?.();
      }
    },
    [onClose],
  );

  const handleKeyDown = useCallback(
    (evt: KeyboardEvent<HTMLDivElement>) => {
      if (evt.key === 'ESC') {
        onClose?.();
      }
    },
    [onClose],
  );

  return (
    <CSSTransition
      timeout={ANIM_DURATION}
      classNames={classes}
      in={in_}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      onEnter={onEnter}
      onEntering={onEntering}
      onEntered={onEntered}
      onExit={onExit}
      onExiting={onExiting}
      onExited={onExited}
      nodeRef={nodeRef}
    >
      <div
        className={c(classes.backdrop, customClasses.backdrop)}
        onClick={handleOverlayClick}
        onKeyDown={handleKeyDown}
        ref={backdropRef}
      >
        <div
          {...restProps}
          className={c(classes.Dialog, className)}
          role="dialog"
          aria-label={title}
          tabIndex={0}
        >
          <div className={c(classes.header, customClasses.header)}>
            <div className={classes.icon}>
              <IconComponent fill="inherit" />
            </div>
            <div className={classes.title}>{title}</div>
            <GridButton
              data-cy="close-dialog"
              className={classes.close}
              onClick={onClose}
              label="Close dialog"
            >
              <Close />
            </GridButton>
          </div>
          <div className={c(classes.body, customClasses.body)}>
            <div className={c(classes.content, customClasses.content)}>
              {children}
            </div>
            {buttons && (
              <div className={classes.buttonContainer}>
                {Object.keys(buttons).map((label, i) => {
                  const handleClick = buttons[label];
                  const primary = primaryButtonIndex === i;
                  return (
                    <Button
                      key={i}
                      data-cy={
                        'dialog-' + label.split(' ').join('-').toLowerCase()
                      }
                      primary={primary}
                      negative={primary && negative}
                      disabled={!handleClick}
                      onClick={
                        handleClick
                          ? () => {
                              const shouldDismiss = handleClick() !== false;
                              if (shouldDismiss) {
                                onClose?.();
                              }
                            }
                          : undefined
                      }
                    >
                      {label.replace(/_/g, ' ')}
                    </Button>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}
