import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { Button } from '../../components/Button';
import { Images } from '../../images';
import {
  IS_BROWSER_EXPERIMENTALLY_SUPPORTED,
  IS_DEVICE_SUPPORTED,
} from '../../utils/device';
import { Clickable } from '../../components/Clickable';
import { TECH_SPEC_URL } from '../../config';
import classes from './NotSupported.module.scss';

function getImage() {
  if (isTablet) return Images.DEVICE_NOT_SUPPORTED_TABLET;
  if (isMobile) return Images.DEVICE_NOT_SUPPORTED_MOBILE;
  return Images.BROWSER_NOT_SUPPORTED;
}

const image = getImage();

interface Props {
  onSkip: () => void;
}

export function NotSupported({ onSkip }: Props) {
  return (
    <div className={classes.NotSupported}>
      {!IS_DEVICE_SUPPORTED ? (
        <div className={classes.content}>
          <img className={classes.image} src={image.src} alt={image.alt} />
          <div className={classes.primary}>
            Sorry... we don't have {isTablet ? 'tablet' : 'mobile'} support yet.
          </div>
          <div className={classes.secondary}>
            Please join on a <strong>desktop</strong> or <strong>laptop</strong>{' '}
            using Chrome or Firefox.
          </div>
          <div className={classes.secondary}>
            For a full overview of tech specs, check{' '}
            <a href={TECH_SPEC_URL} target="_blank" rel="noreferrer">
              here
            </a>
            .
          </div>
        </div>
      ) : (
        <div className={classes.content}>
          <img className={classes.image} src={image.src} alt={image.alt} />
          <div className={classes.primary}>
            {IS_BROWSER_EXPERIMENTALLY_SUPPORTED
              ? 'Yikes... your browser might not be fully supported.'
              : "Bummer... your browser isn't supported yet."}
          </div>
          <div className={classes.secondary}>
            Please join with <strong>Chrome</strong> or <strong>Firefox</strong>
            {IS_BROWSER_EXPERIMENTALLY_SUPPORTED && ' for the best experience'}.
          </div>
          <div className={classes.secondary}>You can download them below.</div>
          <div className={classes.secondary}>
            For a full overview of tech specs, check{' '}
            <a href={TECH_SPEC_URL} target="_blank" rel="noreferrer">
              here
            </a>
            .
          </div>
          <div className={classes.buttons}>
            <Button
              className={classes.button}
              primary
              href="https://www.google.com/chrome/"
            >
              Download Chrome
            </Button>
            <Button
              className={classes.button}
              href="https://www.mozilla.org/firefox/new/"
            >
              Download Firefox
            </Button>
          </div>
          {IS_BROWSER_EXPERIMENTALLY_SUPPORTED && (
            <Clickable className={classes.skip} onClick={onSkip}>
              I understand and wish to proceed.
            </Clickable>
          )}
        </div>
      )}
    </div>
  );
}
