/** timeout (since last heard) until marking a user as offline */
export const OFFLINE_TIMEOUT = 30000;

/** interval to check offline */
export const OFFLINE_CLEANUP_INTERVAL = 10000;

/** interval to take a snapshot of users/huddles and send out to clients */
export const SNAPSHOT_INTERVAL = 200;

/** The number of milliseconds to delay before attempting to reconnect. */
export const RECONNECT_INTERVAL = 1000;

/** The maximum number of milliseconds to delay a reconnection attempt. */
export const MAX_RECONNECT_INTERVAL = 30000;

/** The rate of increase of the reconnect delay. Allows reconnect attempts to
 * back off when problems persist. */
export const RECONNECT_DECAY = 1.5;
