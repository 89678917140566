import { useCallback } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { getServerEndpoint } from '../utils';
import { HttpMethod, Request } from '@poormanvr/common';

type OptionalSpread<T> = T extends undefined ? [] : [T];

function transformResponse<T>(res: AxiosResponse<T>): T {
  return res.data;
}

function transformError<T>(res: AxiosError<T>): T {
  throw res.response?.data; // rethrow transformed data within catch
}

const serverEndpoint = getServerEndpoint();

export function useRequest<R extends Request.Base>(
  method: HttpMethod,
  path: string,
) {
  const isRelativePath = path.startsWith('/');
  const withCredentials = isRelativePath;
  const url = isRelativePath ? serverEndpoint + path : path;
  return useCallback(
    async (...args: OptionalSpread<R['Request']>) => {
      const [requestBody] = args;
      switch (method) {
        case HttpMethod.GET:
        case HttpMethod.DELETE:
          return axios[method]<R['Response']>(url, { withCredentials })
            .then(transformResponse)
            .catch<R['Response']>(transformError);
        case HttpMethod.POST:
        case HttpMethod.PUT:
          return axios[method]<R['Response']>(url, requestBody, {
            withCredentials,
          })
            .then(transformResponse)
            .catch<R['Response']>(transformError);
      }
    },
    [method, url, withCredentials],
  );
}
