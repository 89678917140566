import React from 'react';
import { Clickable } from '../../components/Clickable';
import { GetGatherly } from '../../components/GetGatherly';
import { GATHERLY_LOGO_URL } from '../../config';
import classes from './Header.module.scss';

export function Header(): JSX.Element {
  return (
    <header className={classes.Header}>
      <div className={classes.container}>
        <Clickable href="https://gatherly.io/">
          <img
            src={GATHERLY_LOGO_URL}
            alt="Gatherly logo"
            className={classes.logo}
          />
        </Clickable>

        <GetGatherly classNames={classes.button} />
      </div>
    </header>
  );
}
