import React, {
  FormEventHandler,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Button } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { useRequest } from '../../hooks/useRequest';
import { handleRequestError } from '../../utils';
import { BuildingContext } from '../../contexts/BuildingContext';
import { HttpMethod, IUser, Request } from '@poormanvr/common';

interface Props {
  classes: { readonly [key: string]: string };
  user: IUser;
}

export function AdditionalInfo({ classes, user }: Props): JSX.Element {
  const { setMyId } = useContext(BuildingContext);

  const updateProfile = useRequest<Request.UpdateProfile>(
    HttpMethod.PUT,
    '/api/user/profile',
  );

  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  const handleSubmit: FormEventHandler = useCallback(
    evt => {
      evt.preventDefault();

      updateProfile({
        name: user.name,
        title,
        link,
        email: user.email,
        fields: [],
      })
        .then(({ user }) => {
          setMyId(user.id);
        })
        .catch(handleRequestError);
    },
    [link, setMyId, title, updateProfile, user.email, user.name],
  );

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <p>
        This information will be visible to others attending the event.
        <br />
        <b>You can edit this later in settings.</b>
      </p>
      <TextInput
        label="Title"
        className={classes.inputWrapper}
        InputProps={{
          className: classes.input,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        value={title}
        onChange={evt => setTitle(evt.target.value)}
        data-cy="title-input"
      />
      <TextInput
        label="Personal Link"
        className={classes.inputWrapper}
        InputProps={{
          className: classes.input,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        value={link}
        onChange={evt => setLink(evt.target.value)}
        data-cy="link-input"
      />

      <Button
        primary
        className={classes.button}
        type="submit"
        data-cy="additional-submit"
      >
        Continue
      </Button>
    </form>
  );
}
