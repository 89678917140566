import {
  isChrome,
  isEdgeChromium,
  isChromium,
  isFirefox,
  isMobile,
  isTablet,
  browserName,
  browserVersion,
  deviceType,
} from 'react-device-detect';
import { capitalize } from '../utils';
import { DeviceAndBrowserCompatibility } from '../utils/checkingHub';

const isHeadlessChrome = window.navigator.userAgent.includes('HeadlessChrome');

export const IS_BROWSER_EXPERIMENTALLY_SUPPORTED = isChromium || isEdgeChromium;

export const IS_BROWSER_SUPPORTED = isChrome || isFirefox || isHeadlessChrome;

export const IS_DEVICE_SUPPORTED = !isMobile && !isTablet;

function getDeviceType() {
  let _deviceType = deviceType;
  if (deviceType === 'browser') _deviceType = 'Laptop / Desktop';
  return capitalize(_deviceType);
}

export function checkDeviceCompatibility(): DeviceAndBrowserCompatibility {
  return {
    browserName,
    browserVersion,
    deviceType: getDeviceType(),
    isBrowserSupported: IS_BROWSER_SUPPORTED,
    isBrowserExperimentallySupported: IS_BROWSER_EXPERIMENTALLY_SUPPORTED,
    isDeviceSupported: IS_DEVICE_SUPPORTED,
  };
}
