export interface Message {
  id: string;
  userId: string;
  body: string;
  isDeleted?: boolean;
  at: number;
}

export enum ChatScope {
  USER,
  HUDDLE,
  FLOOR,
  BUILDING,
  ANNOUNCEMENT,
}
