import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TransitionGroup } from 'react-transition-group';

export interface OverlayProps {
  onClose?: () => void;
  outOfBoxRendering?: boolean;
}

export type Overlay = ReactElement<OverlayProps>;

interface Value {
  createOverlayKey: () => React.Key;
  addOverlay: (overlay: Overlay) => void;
  removeOverlay: (key: React.Key) => void;
  replaceOverlay: (overlay: Overlay) => void;
}

export const OverlayContext = createContext<Value>(null as any);

interface Props {
  children: ReactNode;
}

export function OverlayProvider({ children }: Props) {
  const [overlays, setOverlays] = useState<Overlay[]>([]);
  const keyRef = useRef(0);

  const createOverlayKey = useCallback(() => `${keyRef.current++}`, []);

  const addOverlay = useCallback((overlay: Overlay) => {
    setOverlays(overlays => [...overlays, overlay]);
  }, []);

  const removeOverlay = useCallback((key: React.Key) => {
    setOverlays(overlays => overlays.filter(overlay => overlay.key !== key));
  }, []);

  const replaceOverlay = useCallback((newOverlay: Overlay) => {
    setOverlays(overlays =>
      overlays.map(overlay =>
        overlay.key === newOverlay.key ? newOverlay : overlay,
      ),
    );
  }, []);

  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.preventDefault();
        e.stopPropagation();
        const lastOverlay = overlays[overlays.length - 1];
        if (!lastOverlay) return;
        lastOverlay.props.onClose?.();
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [overlays]);

  const value = useMemo<Value>(
    () => ({
      createOverlayKey,
      addOverlay,
      removeOverlay,
      replaceOverlay,
    }),
    [createOverlayKey, addOverlay, removeOverlay, replaceOverlay],
  );

  return (
    <OverlayContext.Provider value={value}>
      {children}
      <TransitionGroup component={null}>
        {overlays.filter(overlay => !overlay.props.outOfBoxRendering)}
      </TransitionGroup>
    </OverlayContext.Provider>
  );
}
