import React, {
  FormEventHandler,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Button } from '../../components/Button';
import { TextInput } from '../../components/TextInput';
import { useRequest } from '../../hooks/useRequest';
import { BuildingContext } from '../../contexts/BuildingContext';
import { EventTracker, getUserParams, handleRequestError } from '../../utils';
import { Clickable } from '../../components/Clickable';
import { Checkbox } from '../../components/Checkbox';
import { postHubSpotForm } from '../../utils/hubspot';
import { OPT_IN_FORM_ID, OPT_IN_PORTAL_ID } from '../../config';
import { HttpMethod, Request, EmailSettings, IUser } from '@poormanvr/common';

interface Props {
  classes: { readonly [key: string]: string };
  onDone: (user: IUser) => void;
}

export function BasicInfo({ classes, onDone }: Props): JSX.Element {
  const {
    building,
    floors,
    chimeMediaRegion,
    blankEmailField,
    eventInfo,
  } = useContext(BuildingContext);

  const userParams = useMemo(() => getUserParams(floors), [floors]);

  const createUser = useRequest<Request.CreateUser>(
    HttpMethod.POST,
    '/api/user',
  );

  const [name, setName] = useState(userParams.name);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hubSpotOptIn, setHubSpotOptIn] = useState(false);

  const handleSubmit: FormEventHandler = useCallback(
    evt => {
      evt.preventDefault();

      if (!chimeMediaRegion) return;

      if (hubSpotOptIn) {
        // NOTE: submissions might not show right away on HubSpot, changing the Date range may help with it
        postHubSpotForm(OPT_IN_PORTAL_ID, OPT_IN_FORM_ID, {
          fields: [
            {
              name: 'email',
              value: email,
            },
          ],
          context: {
            pageUri: window.location.href,
            pageName: eventInfo?.name,
          },
          /*
          The legalConsentOptions are taken from the form on hubspot
          
          to get the subscriptionTypeIds use: https://legacydocs.hubspot.com/docs/methods/email/get_subscriptions
          to see what subscriptions are being used, follow: https://knowledge.hubspot.com/forms/add-notice-and-consent-information-to-your-hubspot-form-or-pop-up-form
          */
          legalConsentOptions: {
            consent: {
              consentToProcess: true,
              text:
                'Gatherly needs the contact information you provide to us to contact you about our products and services. By submitting this form, you also agree to receive marketing updates and other communications from Gatherly. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.',
              communications: [
                {
                  value: true,
                  subscriptionTypeId: 9397904,
                  text:
                    'By submitting this form, you also agree to receive marketing updates and other communications from Gatherly.',
                },
                {
                  value: true,
                  subscriptionTypeId: 9763074,
                  text:
                    'Gatherly needs the contact information you provide to us to contact you about our products and services.',
                },
              ],
            },
          },
        });
      }

      createUser({
        ...userParams,
        name,
        email: email || blankEmailField,
        password,
        title: '',
        link: '',
        fields: [],
        chimeMediaRegion,
        href: window.location.href,
      })
        .then(({ user }) => {
          EventTracker.identify(user, building);

          onDone(user);
        })
        .catch(handleRequestError);
    },
    [
      blankEmailField,
      building,
      chimeMediaRegion,
      createUser,
      email,
      eventInfo?.name,
      hubSpotOptIn,
      name,
      onDone,
      password,
      userParams,
    ],
  );

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextInput
        label="Name"
        required
        autoComplete="name"
        className={classes.inputWrapper}
        InputProps={{
          className: classes.input,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        value={name}
        onChange={evt => setName(evt.target.value)}
        data-cy="name-input"
      />
      {building?.emailSettings !== EmailSettings.DISABLED && (
        <TextInput
          label="Email address"
          type="email"
          className={classes.inputWrapper}
          InputProps={{
            className: classes.input,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
          required={
            building?.emailSettings === EmailSettings.REQUIRED || hubSpotOptIn
          }
          value={email}
          onChange={evt => setEmail(evt.target.value)}
        />
      )}
      {building?.passwordProtected && (
        <TextInput
          label="Password"
          type="password"
          className={classes.inputWrapper}
          InputProps={{
            className: classes.input,
          }}
          InputLabelProps={{
            className: classes.label,
          }}
          required
          value={password}
          onChange={evt => setPassword(evt.target.value)}
        />
      )}
      {building?.emailSettings !== EmailSettings.DISABLED && (
        <Checkbox
          label="Get additional information about Gatherly features and services."
          checked={hubSpotOptIn}
          onChange={evt => setHubSpotOptIn(evt.currentTarget.checked)}
        />
      )}
      <p>
        Gatherly requires access to your webcam and microphone.
        <br />
        By clicking continue you agree to our{' '}
        <Clickable
          className={classes.link}
          href="https://www.gatherly.io/legal/terms-of-use"
          data-cy="terms-of-use"
        >
          Terms of Use
        </Clickable>{' '}
        and{' '}
        <Clickable
          className={classes.link}
          href="https://www.gatherly.io/legal/privacy-policy"
          data-cy="privacy-policy"
        >
          Privacy Policy
        </Clickable>
        .
      </p>

      <Button primary className={classes.button} type="submit" data-cy="submit">
        Continue
      </Button>
    </form>
  );
}
