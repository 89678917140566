import React from 'react';
import ReactDOM from 'react-dom';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import './root.scss';
import reportWebVitals from './reportWebVitals';
import { SENTRY_DSN } from './config';
import { App } from './components/App';
import { GlobalProvider } from './components/GlobalProvider';
import { EventTracker } from './utils';
import { VERSION } from '@poormanvr/common';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    sampleRate: 1,
    environment: process.env.NODE_ENV,
    release: `v${VERSION}`,
    ignoreErrors: [
      // https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
      'ResizeObserver loop limit exceeded', // chrome
      'ResizeObserver loop completed with undelivered notifications.', // firefox
    ],
  });
}

EventTracker.initialize();

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
