import axios from 'axios';
import { HUBSPOT_FORM_API_URL } from '../config';
import { Logger } from '.';

export enum HUBSPOT_FORM_RESULT {
  SUCCESS,
  REJECT,
  NOT_ALLOWED,
  NOT_FOUND,
  ERROR,
}

export async function postHubSpotForm(
  portal_id: string,
  form_id: string,
  data: Record<string, unknown>,
) {
  try {
    const res = await axios.post(
      `${HUBSPOT_FORM_API_URL}${portal_id}/${form_id}`,
      data,
    );

    switch (res.status) {
      case 200:
        Logger.info('HubSpot::postHubSpotForm', 'form submitted successfully.');

        return HUBSPOT_FORM_RESULT.SUCCESS;

      case 400:
        Logger.info('HubSpot::postHubSpotForm', 'form has been rejected.');

        return HUBSPOT_FORM_RESULT.REJECT;

      case 403:
        Logger.info('HubSpot::postHubSpotForm', 'portal is not allow to post');

        return HUBSPOT_FORM_RESULT.NOT_ALLOWED;

      case 404:
        Logger.info('HubSpot::postHubSpotForm', `form () not found.`);

        return HUBSPOT_FORM_RESULT.NOT_FOUND;

      default:
        Logger.error('HubSpot::postHubSpotForm', 'unknown error');

        return HUBSPOT_FORM_RESULT.ERROR;
    }
  } catch (e) {
    Logger.error('HubSpot::postHubSpotForm', `unknown error (${e})`);

    return HUBSPOT_FORM_RESULT.ERROR;
  }
}
