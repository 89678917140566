import React, {
  DependencyList,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Overlay, OverlayContext } from '../contexts/OverlayContext';

interface Options {
  outOfBoxRendering?: boolean;
  noCloseOnUnmount?: boolean;
  closeOnClickOutside?: boolean;
}

export interface ToggleOverlay {
  (open?: boolean, e?: React.MouseEvent<Element, MouseEvent>): void;
}

export function useOverlay(
  render: () => Overlay,
  renderDependencies: DependencyList,
  options: Options = {},
): [Overlay | null, ToggleOverlay] {
  const {
    createOverlayKey,
    addOverlay,
    removeOverlay,
    replaceOverlay,
  } = useContext(OverlayContext);

  const {
    closeOnClickOutside = false,
    outOfBoxRendering = false,
    noCloseOnUnmount = false,
  } = options;

  // use overlayRef as a mirror of overlay, so that useEffect/useCallback can reference overlay without having it as dependency
  const overlayRef = useRef<Overlay | null>(null);
  const [open, setOpen] = useState(false);

  const overlay = useMemo(() => {
    if (!open) {
      return (overlayRef.current = null);
    }
    const oldOverlay = overlayRef.current;
    const node = render();
    const key = oldOverlay?.key ?? createOverlayKey();
    const newOverlay = React.cloneElement(node, {
      key,
      onClose: () => {
        node.props.onClose?.();
        setOpen(false);
        removeOverlay(key);
      },
      outOfBoxRendering,
    });

    if (oldOverlay) {
      replaceOverlay(newOverlay);
    } else {
      addOverlay(newOverlay);
    }

    return (overlayRef.current = newOverlay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    open,
    outOfBoxRendering,
    createOverlayKey,
    addOverlay,
    removeOverlay,
    replaceOverlay,
    ...renderDependencies,
  ]);

  const toggleOverlay: ToggleOverlay = useCallback(
    (open = !overlayRef.current) => {
      setOpen(open);
    },
    [],
  );

  useEffect(() => {
    if (noCloseOnUnmount) return;
    return () => {
      const overlay = overlayRef.current;
      overlay?.props.onClose?.();
    };
  }, [noCloseOnUnmount]);

  useEffect(() => {
    if (!closeOnClickOutside) return;
    const callback = () => {
      const overlay = overlayRef.current;
      overlay?.props.onClose?.();
    };
    document.addEventListener('click', callback);
    return () => {
      document.removeEventListener('click', callback);
    };
  }, [closeOnClickOutside]);

  return [overlay, toggleOverlay];
}
