import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { BuildingContext } from '../../contexts/BuildingContext';
import { c, DEFAULT_BANNER_URL } from '../../utils';
import { OverlayProvider } from '../../contexts/OverlayContext';
import { Header } from './Header';
import classes from './BasicPageLayout.module.scss';
import { Footer } from './Footer';

interface Props {
  children?: ReactNode;
  banner?: boolean;
  footer?: boolean;
  wrapperClassName?: string;
  bannerClassName?: string;
  containerClassName?: string;
  bodyClassName?: string;
}

export function BasicPageLayout({
  children,
  banner,
  footer,
  wrapperClassName,
  bannerClassName,
  containerClassName,
  bodyClassName,
}: Props): JSX.Element {
  const [bannerUrl, setBannerUrl] = useState(DEFAULT_BANNER_URL);

  const { eventInfo } = useContext(BuildingContext);

  useEffect(() => {
    if (eventInfo?.config.landingPage.bannerUrl) {
      setBannerUrl(eventInfo.config.landingPage.bannerUrl);
    }
  }, [eventInfo]);

  return (
    <OverlayProvider>
      <div className={classes.BasicPageLayout}>
        <Header />

        <main className={c(classes.wrapper, wrapperClassName)}>
          <div
            className={c(
              classes.contentContainer,
              !footer && classes.noFooter,
              containerClassName,
            )}
          >
            {banner && (
              <div
                className={c(classes.banner, bannerClassName)}
                style={{
                  backgroundImage: `url(${bannerUrl})`,
                }}
              />
            )}

            <div className={bodyClassName || classes.body}>{children}</div>
          </div>
        </main>

        {footer && <Footer />}
      </div>
    </OverlayProvider>
  );
}
